import { useState } from 'react';

function RegisterSection() {
  const [heading, setHeading] = useState('Get started with world leading marketplace for digital item');
  const [title, setTitle] = useState('Sign Up to CodePlus');
  const [subtitle, setSubtitle] = useState('Sign up CodePlus & unlock a world of opportunities!');
  const [iconTitle, setIconTitle] = useState('10,000+');
  const [iconSubtitle, setIconSubtitle] = useState('CODE');

  const [image, setImage] = useState(null);
  const [iconImage, setIconImage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here, e.g., send data to a backend API
    console.log('Form submitted:', { title, subtitle, heading, iconTitle, iconSubtitle, image, iconImage });
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
    setIconImage(event.target.files[0]);
  };

  return (
    <div className="bg-white px-8 py-4 rounded-md ">
      <h2 className="text-xl font-bold mb-4">Register Section</h2>
      <form onSubmit={handleSubmit} className="border rounded-md">
        <div className='flex flex-wrap md:flex-nowrap md:gap-16 gap-4 px-8 py-2'>
          <div className="md:w-1/2 w-full">
            <label htmlFor="image" className="block text-gray-700 font-bold mb-2">
              Top Image
            </label>
            <input
              type="file"
              id="image"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={handleImageChange}
            />
            <p className="text-gray-600 text-sm mt-1">
              Supported Files: .png, .jpg, .jpeg. Image will be resized into 110x110px
            </p>
          </div>
          <div className="md:w-1/2 w-full">
            <label htmlFor="iconImage" className="block text-gray-700 font-bold mb-2">
              Bottom Image
            </label>
            <input
              type="file"
              id="iconImage"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={handleImageChange}
            />
            <p className="text-gray-600 text-sm mt-1">
              Supported Files: .png, .jpg, .jpeg. Image will be resized into 110x110px
            </p>
          </div>
        </div>
        <div className=" px-8 py-2">
          <label htmlFor="heading" className="block text-gray-700 font-bold mb-2">
            Heading *
          </label>
          <input
            type="text"
            id="heading"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
          />
        </div>
        <div className=" px-8 py-2">
          <label htmlFor="title" className="block text-gray-700 font-bold mb-2">
            Title *
          </label>
          <input
            type="text"
            id="title"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="subtitle" className="block text-gray-700 font-bold mb-2">
            subtitle *
          </label>
          <input
            type="text"
            id="subtitle"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="iconTitle" className="block text-gray-700 font-bold mb-2">
            Icon Title *
          </label>
          <input
            type="text"
            id="iconTitle"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={iconTitle}
            onChange={(e) => setIconTitle(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="iconSubtitle" className="block text-gray-700 font-bold mb-2">
            Icon Subtitle *
          </label>
          <input
            type="text"
            id="iconSubtitle"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={iconSubtitle}
            onChange={(e) => setIconSubtitle(e.target.value)}
          />
        </div>

        <div className='px-8 py-4'>
          <button
            type="submit"
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default RegisterSection;
