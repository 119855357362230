import React, { useState } from 'react';
import { IoSearch } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import { PiEyeSlash } from "react-icons/pi";
import { MdOutlineEdit } from "react-icons/md";
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa6';
function Categories() {
  const [data, setData] = useState([
    {
      selected: false,
      categorieImg: 'https://cdn-icons-png.flaticon.com/128/2721/2721652.png',
      categorieName: 'PHP Scripts',
      status: 'Active',
      featured: "Yes"
    },
    {
      selected: false,
      categorieImg: 'https://script.viserlab.com/codeplus/assets/images/category/65b8c29e536141706607262.png',
      categorieName: 'WordPress',
      status: 'Active',
      featured: "Yes"
    },
    {
      selected: false,
      categorieImg: 'https://script.viserlab.com/codeplus/assets/images/category/65dd79465f3df1709013318.png',
      categorieName: 'Flutter',
      status: 'Active',
      featured: "Yes"
    },
    {
      selected: false,
      categorieImg: 'https://script.viserlab.com/codeplus/assets/images/category/65b8c2b9c00b01706607289.png',
      categorieName: 'HTML5',
      status: 'Active',
      featured: "Yes"
    },
    {
      selected: false,
      categorieImg: 'https://script.viserlab.com/codeplus/assets/images/category/65be2a76144b61706961526.png',
      categorieName: 'User Interface',
      status: 'Active',
      featured: "Yes"
    },
    {
      selected: false,
      categorieImg: 'https://script.viserlab.com/codeplus/assets/images/category/65b8ca26b60851706609190.png',
      categorieName: 'Javascript',
      status: 'Active',
      featured: "Yes"
    },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: '', img: '', status: 'Inactive' });
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 15; // Maximum number of rows per page
  const totalPages = Math.ceil(data.length / rowsPerPage); // Calculate total pages

  // Get current page data
  const currentPageData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const toggleStatus = () => {
    setNewCategory({ ...newCategory, status: newCategory.status === 'Active' ? 'Inactive' : 'Active' });
  };

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll }))); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected)); // If all checkboxes are selected, set selectAll to true
  };

  const handleAddCategory = () => {
    setData([...data, {
      selected: false,
      categorieImg: newCategory.img,
      categorieName: newCategory.name,
      status: newCategory.status,
      featured: "Yes"
    }]);
    setShowModal(false);
    setNewCategory({ name: '', img: '', status: 'Inactive' });
  };

  const handleEditCategory = () => {
    const updatedData = [...data];
    updatedData[selectedCategoryIndex] = {
      ...updatedData[selectedCategoryIndex],
      categorieImg: newCategory.img,
      categorieName: newCategory.name,
      status: newCategory.status
    };
    setData(updatedData);
    setShowEditModal(false);
  };

  const openEditModal = (index) => {
    setSelectedCategoryIndex(index);
    setNewCategory({
      name: data[index].categorieName,
      img: data[index].categorieImg,
      status: data[index].status
    });
    setShowEditModal(true);
  };

  const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility

  const handleAlertButtonClick = () => {
    setShowAlert(true); // Show the alert when the button is clicked
  };

  const handleConfirm = () => {
    //  Execute your unfeature action here
    console.log('Category unfeatured!');
    setShowAlert(false);
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  return (
    <>
      <header className='flex flex-wrap justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Categories</h2>
        </div>
        <div className="flex space-x-4">
          <button onClick={() => setShowModal(true)} className="bg-Select_text hover:bg-Select_text2 text-white font-semibold h-min p-3 text-sm rounded-lg">
            <span className='flex items-center gap-2'><IoMdAddCircleOutline /> Add New Categories</span>
          </button>
        </div>
      </header>

      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-full border-collapse border">
            <thead className='border-b '>
              <th className='border-b'>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-start text-black font-bold uppercase text-sm ">Name</th>
              <th className="px-4 py-4 text-start text-black font-bold uppercase text-sm">Status</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Featured</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Action</th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-start">
                    <div className='flex items-center gap-4'>
                      <img src={item.categorieImg} alt="Product" className="w-8 h-8 inline-block rounded-md" />
                      <div className=''>
                        <span className=''>{item.categorieName}</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start  pl-4"><span className='px-5 py-3 rounded-lg bg-green-100 border border-green-600 text-green-600'>{item.status}</span></td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <span className='px-5 py-3 rounded-lg bg-Select_text border border-yellow-600 text-white'>{item.featured}</span>
                  </td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className='flex gap-2 justify-start flex-wrap'>
                      <button
                        onClick={() => openEditModal(index)}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      >
                        <span className='flex items-center gap-2 '><MdOutlineEdit /> </span>
                      </button>
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#092C4C] hover:bg-[#092C4C] hover:text-white`}
                        onClick={handleAlertButtonClick}
                      >
                        <PiEyeSlash />
                      </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
        <p className="text-gray-600">Showing {currentPageData.length} of {data.length} Results</p>
        <div className="flex gap-1">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
            <FaAngleLeft />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${currentPage === index + 1 ? 'bg-orange-400 text-white' : ''}`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
            <FaAngleRight />
          </button>
        </div>
      </div>
      {/* Add Category Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className='flex items-center justify-between py-2'>
              <h2 className="text-lg font-bold">Add New Category</h2>
              <button onClick={() => setShowModal(false)} className="font-bold">X</button>
            </div>
            <label htmlFor="newCategory" className='font-semibold'>Name</label>
            <input
              type="text"
              htmlFor="newCategory"
              value={newCategory.name}
              onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
              className="w-full mb-4 p-2 border rounded"
            />
            <label htmlFor="image" className='font-semibold'>Image</label>
            <input
              type="file"
              htmlFor="image"
              onChange={(e) => setNewCategory({ ...newCategory, img: URL.createObjectURL(e.target.files[0]) })}
              className="w-full mb-4 p-2 border rounded"
            />
            <div>
              <h1 className='font-semibold'>Status</h1>
              <button onClick={toggleStatus} className={`px-4 py-2 rounded-md mb-4 ${newCategory.status === 'Active' ? 'bg-Select_text' : 'bg-Select_text2'} text-white`}>
                {newCategory.status}
              </button>
            </div>
            <div className="flex justify-end space-x-4">
              <button onClick={handleAddCategory} className="bg-Select_text hover:bg-Select_text2 text-white px-4 py-2 rounded-lg">Add</button>

            </div>
          </div>
        </div>
      )}

      {/* Edit Category Modal */}
      {showEditModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className='flex items-center justify-between py-2'>
              <h2 className="text-lg font-bold mb-4">Edit Category</h2>
              <button onClick={() => setShowEditModal(false)} className="font-bold">X</button>
            </div>
            <label htmlFor="Category" className='font-semibold'>Name</label>
            <input
              type="text"
              htmlFor="Category"
              value={newCategory.name}
              onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
              className="w-full mb-4 p-2 border rounded"
            />
            <label htmlFor="Image" className='font-semibold'>Image</label>
            <input
              type="file"
              htmlFor="Image"
              onChange={(e) => setNewCategory({ ...newCategory, img: URL.createObjectURL(e.target.files[0]) })}
              className="w-full mb-4 p-2 border rounded"
            />
            <h1 className='font-semibold'>Status</h1>
            <button onClick={toggleStatus} className={`px-4 py-2 rounded-md mb-4 ${newCategory.status === 'Active' ? 'bg-Select_text' : 'bg-Select_text2'} text-white`}>
              {newCategory.status}
            </button>
            <div className="flex justify-end space-x-4">
              <button onClick={handleEditCategory} className="bg-Select_text hover:bg-Select_text2 text-white py-2 px-4 rounded-lg">Save</button>

            </div>
          </div>
        </div>
      )}

      {showAlert && (
        <div className="fixed top-0 inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96 mt-10">
            <div className='flex justify-between items-center py-2'>
              <h2 className="text-lg font-bold">Confirmation Alert!</h2>
              <button className="font-bold" onClick={handleCancel}>X</button>
            </div>
            <p className='border-b border-t py-2'>Are you sure to unfeature this category?</p>
            <div className="flex justify-end mt-4">
              <button className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-4 rounded-lg mr-2" onClick={handleCancel}>No</button>
              <button className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-lg" onClick={handleConfirm}>Yes</button>
            </div>
          </div>
        </div>
      )}

    </>
  );
}

export default Categories;
