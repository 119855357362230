import React, { useState } from "react";

function LogoandFavicon() {
  const [logoWhite, setLogoWhite] = useState(null);
  const [logoDark, setLogoDark] = useState(null);
  const [favicon, setFavicon] = useState(null);

  const handleLogoWhiteChange = (event) => {
    setLogoWhite(event.target.files[0]);
  };

  const handleLogoDarkChange = (event) => {
    setLogoDark(event.target.files[0]);
  };

  const handleFaviconChange = (event) => {
    setFavicon(event.target.files[0]);
  };

  const handleSubmit = () => {
    // TODO: Handle form submission logic here
    console.log("Form submitted:", {
      logoWhite,
      logoDark,
      favicon,
    });
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Logo and Favicon</h2>
          <p className="text-gray-500">Upload your logo and favicon here..</p>
        </div>
      </header >
      <div className="mx-auto mt-2 px-8 py-4 ">
        <div className="border-l-4 bg-slate-100 border-blue-500 p-4 rounded-md">
          <p className="text-black text-lg">If the logo and favicon are not changed after you update from this page, please clear the cache from your browser. As we keep the filename the same after the update, it may show the old image for the cache. usually, it works after clear the cache but if you still see the old logo or favicon, it may be caused by server level or network level caching. Please clear them too.</p>
        </div>
        <div className=" border p-4 mt-6 rounded-md">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            <div>
              <h2 className="text-lg font-medium mb-2">Logo for White Background</h2>
              <div className="bg-white border border-gray-200 rounded-lg p-4">
                <img
                  src={logoWhite ? URL.createObjectURL(logoWhite) : null}
                  alt="Logo for white background"
                  className="w-full max-h-48 mt-4"
                />
                <input
                  type="file"
                  className="block mt-6 w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-700"
                  onChange={handleLogoWhiteChange}
                />

              </div>
            </div>
            <div>
              <h2 className="text-lg font-medium mb-2">
                Logo for Dark Background
              </h2>
              <div className="bg-white border border-gray-200 rounded-lg p-4">
                <img
                  src={logoDark ? URL.createObjectURL(logoDark) : null}
                  alt="Logo for dark background"
                  className="w-full max-h-48 mt-4"
                />
                <input
                  type="file"
                  className="block mt-6 w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-700"
                  onChange={handleLogoDarkChange}
                />

              </div>
            </div>
            <div>
              <h2 className="text-lg font-medium mb-2">Favicon</h2>
              <div className="bg-white border border-gray-200 rounded-lg p-4">
                <img
                  src={favicon ? URL.createObjectURL(favicon) : null}
                  alt="Favicon"
                  className="w-full max-h-48 mt-4"
                />
                <input
                  type="file"
                  className="block mt-6 w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-700"
                  onChange={handleFaviconChange}
                />

              </div>
            </div>
          </div>

          <button
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  )
}

export default LogoandFavicon