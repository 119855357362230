import { useState } from "react";

function WeeklyBestSelling() {
  const [title, setTitle] = useState("Weekly Best Selling Item");


  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to a server
    console.log("Form submitted:", { title });
  };

  return (
    <div className="px-8 py-4">
      <h2 className="text-2xl font-bold mb-4">Weekly Best Selling</h2>
      <form onSubmit={handleSubmit} className="border rounded-md">
        <div className="px-8 py-2">
          <label htmlFor="title" className="block text-gray-700 font-bold mb-2">
            title *
          </label>
          <input
            type="text"
            id="title"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="px-8 py-4">
          <button
            type="submit"
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default WeeklyBestSelling;
