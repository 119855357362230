import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
import { LiaFilterSolid } from "react-icons/lia";
import { BsGrid1X2 } from "react-icons/bs";
import { MdOutlineShortText } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";

function ManagePages() {
  const [data, setData] = useState([
    {
      selected: false,
      name: 'Home',
      slug: "/",
    },
    {
      selected: false,
      name: 'Contact',
      slug: "contact",
    },

  ]);
  const [showModal, setShowModal] = React.useState(false);
  const [name, setName] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll }))); // Update all rows with the new selection state
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected)); // If all checkboxes are selected, set selectAll to true
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Manage Pages</h2>
          <p className="text-gray-500"></p>
        </div>
        <button className="bg-Select_text hover:bg-Select_text2 text-white font-semibold h-min p-3 text-sm rounded-lg">
          <span className='flex items-center gap-2'><IoMdAddCircleOutline /> Add Pages</span>
        </button>
      </header >
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-full  border-collapse border">
            <thead className='border-b'>
              <th className='border-b'>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Name</th>

              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Slug</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Action</th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4 ">
                    <span className=''>{item.name}</span>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">{item.slug}</td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className='flex gap-2 justify-start flex-wrap'>
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      ><IoSettingsOutline />
                      </button>
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#092C4C] hover:bg-[#092C4C] hover:text-white`}
                      ><MdOutlineEdit />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>

    </>
  )
}

export default ManagePages