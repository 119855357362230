import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBoxArchive } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { BiSolidCommentDetail } from "react-icons/bi";
import { BsCloudCheckFill } from "react-icons/bs";
import { FaMoneyBill1 } from "react-icons/fa6";
import { IoWallet } from "react-icons/io5";
import { RiBankFill } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import { LuLogIn } from "react-icons/lu";
import { BsPlusSquareFill } from "react-icons/bs";
import { TbSquareMinusFilled } from "react-icons/tb";
import { FaListUl } from "react-icons/fa";
import { IoIosNotificationsOutline } from "react-icons/io";
import { FaBan } from "react-icons/fa";

const AuthorDetails = () => {
  const [totalProductCount, setTotalProductCount] = useState("100");
  const [reviewsCount, setReviewsCount] = useState("33");
  const [commentsCount, setCommentsCount] = useState("29");
  const [soldItemsCount, setSoldItemsCount] = useState("29");
  const [balanceCount, setBalanceCount] = useState("24,975.50");
  const [paymentsCount, setPaymentsCount] = useState("0.00");
  const [withdrawalsCount, setWithdrawalsCount] = useState("0.00");
  const [transactionsCount, setTransactionsCount] = useState("223");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "United States",
    emailVerified: false,
    mobileVerified: false,
    twoFactorVerified: false,
    kycVerified: false,
  });

  const countries = ["United States", "India", "Canada", "United Kingdom", "Australia", "Germany"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleVerification = (field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    alert("Form Submitted!");
  };

  const navigate = useNavigate();

  // Function to navigate to the total Products
  const handleTotalProducts = () => {
    navigate(`/manageproducts/allproducts`);
  };
  // Function to navigate to the Reviews
  const handleReviews = () => {
    navigate(`/reviewscomments/reviews`);
  };
  // Function to navigate to the Comments
  const handleComments = () => {
    navigate(`/reviewscomments/comments`);
  };
  // Function to navigate to the Sold items
  const handleSoldItems = () => {
    navigate(`/managesales/salesitems`);
  };
  // Function to navigate to the Balance
  const handleBalance = () => {
    navigate(`/report/transactionhistory`);
  };
  // Function to navigate to the Payments
  const handlePayments = () => {
    navigate(`/payments/allpayments`);
  };
  // Function to navigate to the Withdrawals
  const handleWithdrawals = () => {
    navigate(`/withdrawals/allwithdrawals`);
  };
  // Function to navigate to the Transaction
  const handleTransaction = () => {
    navigate(`/report/transactionhistory`);
  };
  // Function to navigate to the Logins
  const handleLogins = () => {
    navigate(`/report/loginhistory`);
  };
  // Function to navigate to the Notification
  const handleNotification = () => {
    navigate(`/manageusers/notificationsendto/username`);
  };
  const [isAddBalanceModalOpen, setIsAddBalanceModalOpen] = useState(false);
  const [isSubtractBalanceModalOpen, setIsSubtractBalanceModalOpen] = useState(false);
  const [isBanUserModalOpen, setIsBanUserModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [remark, setRemark] = useState("");

  const handleAddBalanceOpenModal = () => {
    setIsAddBalanceModalOpen(true);
  };
  const handleSubtractBalanceOpenModal = () => {
    setIsSubtractBalanceModalOpen(true);
  };
  const handleBanUserOpenModal = () => {
    setIsBanUserModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsAddBalanceModalOpen(false);
    setIsSubtractBalanceModalOpen(false);
    setIsBanUserModalOpen(false);
    setAmount("");
    setRemark("");
  };


  return (
    <div className="px-8 py-4">
      <header className='flex items-center justify-between px-9'>
        <h1 className='text-lg font-semibold'>Author Detail - username</h1>
        <button className="bg-Select_text hover:bg-Select_text2 text-white font-semibold h-min p-3 text-sm rounded-lg">
          <span className='flex items-center gap-2'><LuLogIn /> Login as User</span>
        </button>
      </header>

      <div className='grid grid-cols-1 gap-2 mt-4'>

        <div className='p-4'>
          <div className='grid md:grid-cols-4 grid-cols-1 gap-6 '>
            {/* total products */}
            <div onClick={() => handleTotalProducts()} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-green-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-green-300 border-l-[3px] text-green-500 text-xl">
                  <FaBoxArchive />
                </div>
                <div className='flex flex-col'>
                  <span className="text-xl font-bold">{totalProductCount}</span>
                  <span className="text-gray-500">Total Products</span>
                </div>
              </div>
            </div>

            {/* reviwes */}
            <div onClick={() => handleReviews()} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-yellow-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-yellow-300 border-l-[3px] text-yellow-500 text-xl">
                  <FaStar />
                </div>
                <div className='flex flex-col'>
                  <span className="text-xl font-bold">{reviewsCount}</span>
                  <span className="text-gray-500">Reviews</span>
                </div>
              </div>
            </div>

            {/* Comments */}
            <div onClick={() => handleComments()} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-orange-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-orange-300 border-l-[3px] text-orange-500 text-xl">
                  <BiSolidCommentDetail />
                </div>
                <div className='flex flex-col'>
                  <span className="text-xl font-bold">{commentsCount}</span>
                  <span className="text-gray-500">Comments</span>
                </div>
              </div>
            </div>

            {/* Sold Items */}
            <div onClick={() => handleSoldItems()} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-blue-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-blue-300 border-l-[3px] text-blue-500 text-xl">
                  <BsCloudCheckFill />
                </div>
                <div className='flex flex-col'>
                  <span className="text-xl font-bold">{soldItemsCount}</span>
                  <span className="text-gray-500">Sold Items</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className='p-4'>
          <div className='grid md:grid-cols-4 grid-cols-1 gap-6 '>
            {/* balance */}
            <div onClick={() => handleBalance()} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-red-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-red-300 border-l-[3px] text-red-500 text-xl">
                  <FaMoneyBill1 />
                </div>
                <div className='flex flex-col'>
                  <span className="text-xl font-bold">${balanceCount}</span>
                  <span className="text-gray-500">Balance</span>
                </div>
              </div>
            </div>

            {/* payments */}
            <div onClick={() => handlePayments()} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-indigo-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-indigo-300 border-l-[3px] text-indigo-500 text-xl">
                  <IoWallet />
                </div>
                <div className='flex flex-col'>
                  <span className="text-xl font-bold">${paymentsCount}</span>
                  <span className="text-gray-500">Payments</span>
                </div>
              </div>
            </div>

            {/* Withdrawals */}
            <div onClick={() => handleWithdrawals()} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-gray-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-gray-300 border-l-[3px] text-gray-500 text-xl">
                  <RiBankFill />
                </div>
                <div className='flex flex-col'>
                  <span className="text-xl font-bold">${withdrawalsCount}</span>
                  <span className="text-gray-500">Withdrawals</span>
                </div>
              </div>
            </div>

            {/* Transactions */}
            <div onClick={() => handleTransaction()} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-cyan-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-cyan-300 border-l-[3px] text-cyan-500 text-xl">
                  <GrTransaction />
                </div>
                <div className='flex flex-col'>
                  <span className="text-xl font-bold">{transactionsCount}</span>
                  <span className="text-gray-500">Transactions</span>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div className='grid grid-cols-1 gap-2 mt-4'>
        <div className='px-4'>
          <div className='grid md:grid-cols-5 grid-cols-1 gap-2'>
            {/* +Balance */}
            <div onClick={handleAddBalanceOpenModal} className="bg-white rounded-xl shadow-md py-4 px-8 w-60 flex items-center justify-between cursor-pointer hover:bg-green-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-green-300 border-l-[3px] text-green-500 text-xl">
                  <BsPlusSquareFill />
                </div>
                <div className='flex flex-col'>
                  <span className="text-gray-500">Balance</span>
                </div>
              </div>
            </div>

            {/* -Balance */}
            <div onClick={handleSubtractBalanceOpenModal} className="bg-white rounded-xl shadow-md py-4 px-8 w-60 flex items-center justify-between cursor-pointer hover:bg-red-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-red-300 border-l-[3px] text-red-500 text-xl">
                  <TbSquareMinusFilled />
                </div>
                <div className='flex flex-col'>
                  <span className="text-gray-500">Balance</span>
                </div>
              </div>
            </div>

            {/* Logins */}
            <div onClick={() => handleLogins()} className="bg-white rounded-xl shadow-md py-4 px-8 w-60 flex items-center justify-between cursor-pointer hover:bg-indigo-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-indigo-300 border-l-[3px] text-indigo-500 text-xl">
                  <FaListUl />
                </div>
                <div className='flex flex-col'>
                  <span className="text-gray-500">Logins</span>
                </div>
              </div>
            </div>

            {/* Notification */}
            <div onClick={() => handleNotification()} className="bg-white rounded-xl shadow-md py-4 px-8 w-60 flex items-center justify-between cursor-pointer hover:bg-gray-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-gray-300 border-l-[3px] text-gray-500 text-xl">
                  <IoIosNotificationsOutline />
                </div>
                <div className='flex flex-col'>
                  <span className="text-gray-500">Notification</span>
                </div>
              </div>
            </div>

            {/* Ban User */}
            <div onClick={() => handleBanUserOpenModal()} className="bg-white rounded-xl shadow-md py-4 px-8 w-60 flex items-center justify-between cursor-pointer hover:bg-orange-50">
              <div className="flex items-center gap-2">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-orange-300 border-l-[3px] text-orange-500 text-xl">
                  <FaBan />
                </div>
                <div className='flex flex-col'>
                  <span className="text-gray-500">Ban User</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="mt-8 mx-5 px-6 py-3 bg-gray-50 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4 pt-2">Information of username</h2>
        <form onSubmit={handleSubmit} className="space-y-4 border-t pt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Mobile Number (with country code)</label>
              <input
                type="tel"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-gray-700">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-gray-700">Country</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              >
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700">Zip/Postal Code</label>
              <input
                type="text"
                name="zip"
                value={formData.zip}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
          </div>
          <h3 className="text-xl font-semibold mb-2">Verification</h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 border-b pb-4">
            <button
              type="button"
              className={`py-2 px-4 rounded ${formData.emailVerified ? "bg-green-500" : "bg-red-500"} text-white`}
              onClick={() => toggleVerification("emailVerified")}
            >
              {formData.emailVerified ? "Email Verified" : "Email Unverified"}
            </button>
            <button
              type="button"
              className={`py-2 px-4 rounded ${formData.mobileVerified ? "bg-green-500" : "bg-red-500"} text-white`}
              onClick={() => toggleVerification("mobileVerified")}
            >
              {formData.mobileVerified ? "Mobile Verified" : "Mobile Unverified"}
            </button>
            <button
              type="button"
              className={`py-2 px-4 rounded ${formData.twoFactorVerified ? "bg-green-500" : "bg-red-500"} text-white`}
              onClick={() => toggleVerification("twoFactorVerified")}
            >
              {formData.twoFactorVerified ? "2FA Verified" : "2FA Unverified"}
            </button>
            <button
              type="button"
              className={`py-2 px-4 rounded ${formData.kycVerified ? "bg-green-500" : "bg-red-500"} text-white`}
              onClick={() => toggleVerification("kycVerified")}
            >
              {formData.kycVerified ? "KYC Verified" : "KYC Unverified"}
            </button>
          </div>

          <div>
            <button type="submit" className="py-2 px-8 bg-Select_text text-white rounded hover:bg-Select_text2">
              Submit
            </button>
          </div>
        </form>
      </div>

      {/* Add Balance Modal */}
      {isAddBalanceModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <div className='flex items-center justify-between '>
              <h3 className="text-xl font-semibold">Add Balance</h3>
              <button
                type="button"
                className="font-semibold"
                onClick={handleCloseModal}
              >
                X
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Amount</label>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                  placeholder='Please Provide Positive Amount'
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Remark</label>
                <textarea
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                  required
                  placeholder='Remark....'
                ></textarea>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Subtract Balance Modal */}
      {isSubtractBalanceModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <div className='flex items-center justify-between '>
              <h3 className="text-xl font-semibold">Subtract Balance</h3>
              <button
                type="button"
                className="font-semibold"
                onClick={handleCloseModal}
              >
                X
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Amount</label>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                  placeholder='Please Provide Positive Amount'
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Remark</label>
                <textarea
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                  required
                  placeholder='Remark....'
                ></textarea>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Ban User*/}
      {isBanUserModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <div className='flex items-center justify-between '>
              <h3 className="text-xl font-semibold">Subtract Balance</h3>
              <button
                type="button"
                className="font-semibold"
                onClick={handleCloseModal}
              >
                X
              </button>
            </div>
            <div>
              <p className='py-2'>If you ban this user he/she won't able to access his/her dashboard.</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Remark</label>
                <textarea
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                  required
                  placeholder='Remark....'
                ></textarea>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthorDetails;