import React, { useState } from 'react';
import {
  FaUser,
  FaLock,
  FaInstagram,
  FaFacebookF,
  FaTwitter,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';

function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const [massage, setMassage] = React.useState('');
  const [status, setStatus] = React.useState(false);
  const handleLogin = (e) => {
    e.preventDefault(); 

    axios({
    
      url: `https://fickrr.brandeducer.xyz/api/custom-login`,
      method: 'POST',
     
      data: {
        email,
        password,
      },
    })
      .then((res) => {
        console.log('response login', res.data);
        setMassage(res.data.message); 
        if (res) {
          console.log(res);
          localStorage.setItem('admin', JSON.stringify(res.data)); 
          console.success('Login Successfully', { message: res.data.message });
        } else {
          console.error('Invalid Username and Password');
        }
      })
      .catch((err) => {
        console.error('Error occurred:', err);
        console.error('An error occurred. Please try again.');
      });
  };



  return (
    <>
      <div className="container loginSection">
        <div className="screen">
          <div className="screen__content">
            <form className="login" onSubmit={handleLogin}>
              <div className="login__field">
                <FaUser className="login__icon" />
                <input
                  type="text"
                  className="login__input"
                  placeholder="User name / Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Use setLogin to update the login state
                />
              </div>
              <div className="login__field">
                <FaLock className="login__icon" />
                <input
                  type="password"
                  className="login__input"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <button className="button login__submit" type="submit">
                <span className="button__text">Log In Now</span>
                <i className="button__icon fas fa-chevron-right"></i>
              </button>
            </form>
            <div className="social-login">
              <h3>Log in via</h3>
              <div className="social-icons">
                <a href="#" className="social-login__icon">
                  <FaInstagram />
                </a>
                <a href="#" className="social-login__icon">
                  <FaFacebookF />
                </a>
                <a href="#" className="social-login__icon">
                  <FaTwitter />
                </a>
              </div>
            </div>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
