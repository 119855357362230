import React, { useState } from 'react';

const SitemapXML = () => {
  const [sitemapXml, setSitemapXml] = useState('');

  const handleChange = (e) => {
    setSitemapXml(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted Sitemap XML:', sitemapXml);
    alert('Sitemap XML has been submitted!');
  };

  // Function to calculate line numbers based on textarea value
  const getLineNumbers = () => {
    return sitemapXml.split('\n').map((_, index) => index + 1).join('\n');
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Sitemap XML</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >

      <div className=" mt-4 gap-4 px-8">
        <h1 className="text-xl font-bold mb-4">Insert Sitemap XML</h1>
        <form onSubmit={handleSubmit} className="relative">
          <div className="flex">
            {/* Line Numbers */}
            <div
              className="bg-black text-gray-400 rounded-l-lg p-2 text-right select-none"
              style={{ minWidth: '50px' }}
            >
              <pre>{getLineNumbers()}</pre>
            </div>

            {/* Sitemap Editor */}
            <textarea
              value={sitemapXml}
              onChange={handleChange}
              className="w-full min-h-96 p-2 bg-black rounded-r-lg text-white border-none focus:outline-none  resize-none"
              placeholder="Insert your Sitemap XML content here..."
            />
          </div>

          <button
            type="submit"
            className=" mt-4 bg-Select_text text-white px-4 py-2 rounded-md hover:bg-Select_text2"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default SitemapXML;
