import React, { useState } from 'react';


function LicenseInformation() {
  const [personalLicenseItems, setPersonalLicenseItems] = useState([
    { name: 'Future Updates' },
    { name: '4 Months Support' },
  ]);
  const [commercialLicenseItems, setCommercialLicenseItems] = useState([
    { name: 'Future Updates' },
    { name: '8 Months Support' },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [licenseType, setLicenseType] = useState('');

  const handleAddItem = () => {
    const newItem = { name: newItemName };
    if (licenseType === 'personal') {
      setPersonalLicenseItems([...personalLicenseItems, newItem]);
    } else {
      setCommercialLicenseItems([...commercialLicenseItems, newItem]);
    }
    setShowModal(false); // Close the modal after adding the item
    setNewItemName(''); // Clear the input field
  };

  const openModal = (type) => {
    setLicenseType(type);
    setShowModal(true); // Open the modal
  };

  const handleRemoveItem = (index, licenseType) => {
    if (licenseType === 'personal') {
      const updatedItems = [...personalLicenseItems];
      updatedItems.splice(index, 1);
      setPersonalLicenseItems(updatedItems);
    } else {
      const updatedItems = [...commercialLicenseItems];
      updatedItems.splice(index, 1);
      setCommercialLicenseItems(updatedItems);
    }
  };


  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">License Information</h2>
          <p className="text-gray-500">View and manage software license information. </p>
        </div>
      </header >
      <div className="flex flex-col justify-center items-center mt-4 gap-4 px-8 w-full">
        <div className="border rounded-lg shadow-md md:w-1/2 w-full">
          <div className='flex flex-wrap gap-4 items-center justify-between md:px-4 md:py-2 px-2 py-1 bg-gray-200'>
            <h2 className="text-xl font-bold text-gray-900">Personal License</h2>
            <button
              className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded"
              onClick={() => openModal('personal')}
            >
              + Add New
            </button>
          </div>
          <div className="mt-4 px-4 py-2">
            {personalLicenseItems.map((item, index) => (
              <div key={index} className="flex bg-purple-200 justify-between items-center py-2 px-4 rounded-md border-b mb-4">
                <span>{item.name}</span>
                <button
                  className="bg-red-500 hover:bg-red-700 px-3 py-2 rounded-md text-white font-bold"
                  onClick={() => handleRemoveItem(index, 'personal')}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div className='px-4 py-2 border-t'>
            <button className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-6 rounded">
              Save
            </button>
          </div>
        </div>

        <div className="border rounded-lg shadow-md md:w-1/2 w-full">
          <div className='flex flex-wrap gap-4 items-center justify-between px-4 py-2 bg-gray-200'>
            <h2 className="text-xl font-bold text-gray-900">Commercial License</h2>
            <button
              className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded"
              onClick={() => openModal('commercial')}
            >
              + Add New
            </button>
          </div>
          <div className="mt-4 px-4 py-2">
            {commercialLicenseItems.map((item, index) => (
              <div key={index} className="flex bg-purple-200 justify-between items-center py-2 px-4 rounded-md border-b mb-4">
                <span>{item.name}</span>
                <button
                  className="bg-red-500 hover:bg-red-700 px-3 py-2 rounded-md text-white font-bold"
                  onClick={() => handleRemoveItem(index, 'commercial')}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div className='px-4 py-2 border-t'>
            <button className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-6 rounded">
              Save
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Add New Item to {licenseType === 'personal' ? 'Personal' : 'Commercial'} License</h2>
            <input
              type="text"
              className="border p-2 rounded w-full mb-4"
              placeholder="Enter item name"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-Select_text hover:bg-Select_text2 text-white px-4 py-2 rounded"
                onClick={handleAddItem}
              >
                Add Item
              </button>
            </div>
          </div>
        </div>
      )}

    </>
  )
}


export default LicenseInformation