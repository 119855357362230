import React, { useState } from 'react';
import { HiMenuAlt3 } from 'react-icons/hi';
import { Link, Route, Router, Routes } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';
import { GoFile } from "react-icons/go";
import { IoCloudOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { HiOutlineArchiveBox } from "react-icons/hi2";
import { IoPersonOutline } from "react-icons/io5";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { GoCommentDiscussion } from "react-icons/go";
import { LuChevronDownCircle } from "react-icons/lu";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { PiBank } from "react-icons/pi";
import { PiTicket } from "react-icons/pi";
import { TbListDetails } from "react-icons/tb";
import { AiOutlineLike } from "react-icons/ai";
import { LiaServerSolid } from "react-icons/lia";
import { IoBugOutline } from "react-icons/io5";
import { PiCaretCircleRight } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import Png_Logo from '../images/Png_Logo.png'
import Square_Icon from '../images/Square_Icon.jpg'
function SideBarPage() {
  const [open, setOpen] = useState(true);

  const [selectedTab, setSelectedTab] = useState('');

  const handleTabSelect = (tabName) => {
    setSelectedTab(tabName);
  };
  console.log(selectedTab);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  // Add a new state for toggling the StoreConfigure dropdown
  const [storeConfigureDropdownOpen, setStoreConfigureDropdownOpen] = useState(false);

  // Function to toggle StoreConfigure dropdown
  const toggleStoreConfigureDropdown = () => {
    setStoreConfigureDropdownOpen(!storeConfigureDropdownOpen);
  };

  // Add a new state for toggling the NotificationSettings dropdown
  const [notificationSettingsDropdownOpen, setNotificationSettingsOpen] = useState(false);

  // Function to toggle StoreConfigure dropdown
  const toggleNotificationSettingsDropdown = () => {
    setNotificationSettingsOpen(!notificationSettingsDropdownOpen);
  };

  // Add a new state for toggling the PaymentGateways dropdown
  const [PaymentGatewaysDropdownOpen, setPaymentGatewaysOpen] = useState(false);

  // Function to toggle StoreConfigure dropdown
  const togglePaymentGatewaysDropdown = () => {
    setPaymentGatewaysOpen(!PaymentGatewaysDropdownOpen);
  };

  return (
    <section className="flex">
      <div
        className={`bg-white min-h-screen border-r-2 ${open ? 'w-76' : 'w-20'
          } duration-500 text-unSelect_text px-4`}
      >
        <div className="w-full z-50 bg-white py-3 flex justify-start border-b-2 sticky top-0">
          <img
            src={open ? Png_Logo : Square_Icon}  // Toggle between two images
            className='w-36'
            alt="logo"
            onClick={() => setOpen(!open)}
          />
        </div>
        <div className="mt-4 flex flex-col gap-1 ">

          {/* Dashboard */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm gap-2 p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(AiOutlineHome, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500 text-lg  ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                } ${selectedTab === 'Dashboard' ? 'font-medium' : 'font-thin'}`}
              onClick={() => handleTabSelect('Dashboard')}
            >
              Dashboard
            </button>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Dashboard
            </h2>
          </Link>


          {/* Manage Categories */}
          <Link
            to="/managecategories"
            className={`
                 group flex items-center text-sm  gap-2 p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(GoFile, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500 text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'manageCategories' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                handleTabSelect('manageCategories');
                toggleDropdown('manageCategories');
              }}
            >
              Manage Categories
            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'manageCategories' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Manage Categories
            </h2>
          </Link>
          {open && openDropdownId === 'manageCategories' && (
            <div className=" ms-6 flex flex-col gap-0 w-44 items-end">
              <Link
                to="/managecategories/categories"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Categories'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Categories')}
              >
                <span className='flex gap-2 items-center'><GoDotFill /> Categories</span>
              </Link>

              <Link
                to="/managecategories/sub-categories"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Sub-Categories'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Sub-Categories')}
              >
                <span className='flex gap-2 items-center'><GoDotFill /> Sub-Categories</span>

              </Link>

              <Link
                to="/managecategories/review"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Review'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Review')}
              >
                <span className='flex gap-2 items-center'><GoDotFill /> Review</span>

              </Link>

            </div>
          )}


          {/* Manage Sales */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm  gap-2 p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(IoCloudOutline, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500  text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'manageSales' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                { toggleDropdown('manageSales'); handleTabSelect('manageSales'); }
              }}
            >
              Manage Sales
            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'manageSales' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Manage Sales
            </h2>
          </Link>
          {open && openDropdownId === 'manageSales' && (
            <div className="ms-6 flex flex-col gap-0 w-44 items-end">

              <Link
                to="/managesales/salesitems"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'SaleItem'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('SaleItem')}
              >
                <span className='flex gap-2 items-center'><GoDotFill /> Sale Item</span>
              </Link>

              <Link
                to="/managesales/refundeditems"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'RefundedItems'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('RefundedItems')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Refunded Items</span>

              </Link>

              <Link
                to="/managesales/allsales"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'AllSales'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('AllSales')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />All Sales</span>

              </Link>

            </div>
          )}


          {/* Manage Products */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm  gap-2 p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(HiOutlineArchiveBox, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500  text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'manageProducts' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                { toggleDropdown('manageProducts'); handleTabSelect('manageProducts'); }
              }}
            >
              Manage Products
            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'manageProducts' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Manage Products
            </h2>
          </Link>
          {open && openDropdownId === 'manageProducts' && (
            <div className="ms-6 flex flex-col gap-0 w-48 items-end">

              <Link
                to="/manageproducts/allproducts"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'AllProducts'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('AllProducts')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />All</span>
              </Link>

              <Link
                to="/manageproducts/approvedproducts"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'ApprovedProducts'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('ApprovedProducts')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Approved</span>

              </Link>

              <Link
                to="/manageproducts/pendingproducts"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'PendingProducts'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('PendingProducts')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Pending</span>
              </Link>
              <Link
                to="/manageproducts/softrejectedproducts"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'SoftRejectedProducts'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('SoftRejectedProducts')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Soft Rejected</span>
              </Link>
              <Link
                to="/manageproducts/hardrejectedproducts"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'HardRejectedProducts'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('HardRejectedProducts')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Hard Rejected</span>
              </Link>
              <Link
                to="/manageproducts/softdisabledproducts"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'SoftDisabledProducts'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('SoftDisabledProducts')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Soft Disabled </span>
              </Link>
              <Link
                to="/manageproducts/permanentdisabledproducts"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'PermanentDisabledProducts'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('PermanentDisabledProducts')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Permanent Disabled</span>
              </Link>

            </div>
          )}

          {/* Manage Author */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm  gap-2  p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(IoPersonOutline, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500  text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'manageAuthor' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                toggleDropdown('manageAuthor'); handleTabSelect('manageAuthor');
              }}
            >
              Manage Author
            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'manageAuthor' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Manage Author
            </h2>
          </Link>
          {open && openDropdownId === 'manageAuthor' && (
            <div className="ms-6 flex flex-col gap-0 w-44 items-end">

              <Link
                to="/manageauthors/authors"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Authors'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Authors')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Authors</span>

              </Link>

              <Link
                to="/manageauthors/authorlevel"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'AuthorLevel'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('AuthorLevel')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Author Level</span>

              </Link>

            </div>
          )}

          {/* Manage Users */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm  gap-2 p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(HiOutlineUserGroup, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500 text-lg 
                ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  
                ${selectedTab === 'manageUsers' ? 'font-medium' : 'font-thin'}`}

              onClick={() => {
                toggleDropdown('manageUsers'); handleTabSelect('manageUsers');
              }}
            >
              Manage Users

            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'manageUsers' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Manage Users
            </h2>
          </Link>
          {open && openDropdownId === 'manageUsers' && (
            <div className="ms-6 flex flex-col gap-0 w-44 items-end">

              <Link
                to="/manageusers/activeusers"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'ActiveUsers'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('ActiveUsers')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Active Users</span>

              </Link>

              <Link
                to="/manageusers/bannedusers"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'BannedUsers'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('BannedUsers')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Banned Users</span>

              </Link>
              <Link
                to="/manageusers/emailunverifiedusers"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'EmailUnverifiedUsers'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('EmailUnverifiedUsers')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Email Unverified</span>

              </Link>
              <Link
                to="/manageusers/mobileUnverifiedUsers"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'MobileUnverifiedUsers'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('MobileUnverifiedUsers')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Mobile Unverified</span>

              </Link>
              <Link
                to="/manageusers/kyc-unverifiedusers"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'KYCUnverifiedUsers'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('KYCUnverifiedUsers')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />KYC Unverified</span>

              </Link>
              <Link
                to="/manageusers/kyc-pendingusers"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'KYCPendingUsers'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('KYCPendingUsers')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />KYC Pending</span>

              </Link>
              <Link
                to="/manageusers/userswithbalance"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'UsersWithBalance'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('UsersWithBalance')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />With Balance</span>

              </Link>
              <Link
                to="/manageusers/allusers"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'AllUsers'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('AllUsers')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />All Users</span>

              </Link>
              <Link
                to="/manageusers/send-notification"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'SendNotification'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('SendNotification')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Send Notification</span>

              </Link>

            </div>
          )}

          {/* Reviews and  Comments */}
          <Link
            to="/dashboard"
            className={`
                 group flex justify-between items-center text-sm  gap-2 p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(GoCommentDiscussion, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500  text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'ReviewsComments' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                toggleDropdown('ReviewsComments'); handleTabSelect('ReviewsComments');
              }}
            >
              Reviews & Comments
            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'ReviewsComments' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Reviews & Comments
            </h2>
          </Link>
          {open && openDropdownId === 'ReviewsComments' && (
            <div className="ms-6 flex flex-col gap-0 w-44 items-end">

              <Link
                to="/reviewscomments/reviews"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Reviews'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Reviews')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Reviews</span>

              </Link>

              <Link
                to="/reviewscomments/comments"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Comments'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Comments')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Comments</span>

              </Link>

            </div>
          )}

          {/* Payments */}
          <Link
            to="/dashboard"
            className={`
                 group flex justify-between items-center text-sm  gap-2  p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(LiaFileInvoiceDollarSolid, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500  text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'Payments' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                toggleDropdown('Payments'); handleTabSelect('Payments');
              }}
            >
              Payments
            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'Payments' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Payments
            </h2>
          </Link>
          {open && openDropdownId === 'Payments' && (
            <div className="ms-6 flex flex-col gap-0 w-48 items-end">

              <Link
                to="/payments/pendingpayments"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'PendingPayments'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('PendingPayments')}
              >
                <span className='flex gap-2 text-sm items-center'><GoDotFill />Pending Payments</span>

              </Link>

              <Link
                to="/payments/approvedpayments"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'ApprovedPayments'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('ApprovedPayments')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Approved Payments</span>

              </Link>
              <Link
                to="/payments/successfulpayments"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'SuccessfulPayments'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('SuccessfulPayments')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Successful Payments</span>

              </Link>
              <Link
                to="/payments/rejectedpayments"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'RejectedPayments'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('RejectedPayments')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Rejected Payments</span>

              </Link>
              <Link
                to="/payments/initiatedpayments"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'InitiatedPayments'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('InitiatedPayments')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Initiated Payments</span>

              </Link>
              <Link
                to="/payments/allpayments"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'AllPayments'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('AllPayments')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />All Payments</span>

              </Link>

            </div>
          )}

          {/* Withdrawals */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm  gap-2  p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(PiBank, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500  text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'Withdrawals' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                toggleDropdown('Withdrawals'); handleTabSelect('Withdrawals');
              }}
            >
              Withdrawals
            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'Withdrawals' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Withdrawals
            </h2>
          </Link>
          {open && openDropdownId === 'Withdrawals' && (
            <div className="ms-6 flex flex-col gap-0 w-52 items-end">

              <Link
                to="/withdrawals/pendingwithdrawals"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'PendingWithdrawals'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('PendingWithdrawals')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Pending Withdrawals</span>

              </Link>

              <Link
                to="/withdrawals/approvedwithdrawals"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'ApprovedWithdrawals'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('ApprovedWithdrawals')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Approved Withdrawals</span>

              </Link>
              <Link
                to="/withdrawals/rejectedwithdrawals"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'RejectedWithdrawals'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('RejectedWithdrawals')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Rejected Withdrawals</span>

              </Link>
              <Link
                to="/withdrawals/allwithdrawals"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'AllWithdrawals'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('AllWithdrawals')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />All Withdrawals</span>

              </Link>
            </div>
          )}

          {/* Support Ticket */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm  gap-2 p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(PiTicket, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500 text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'SupportTicket' ? 'font-medium' : 'font-thin'}`}

              onClick={() => {
                toggleDropdown('SupportTicket'); handleTabSelect('SupportTicket');
              }}
            >
              Support Ticket

            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'SupportTicket' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Support Ticket
            </h2>
          </Link>
          {open && openDropdownId === 'SupportTicket' && (
            <div className="ms-6 flex flex-col gap-0 w-44 items-end">

              <Link
                to="/supportticket/pendingtickets"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'PendingTickets'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('PendingTickets')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Pending Tickets</span>
              </Link>
              <Link
                to="/supportticket/closedtickets"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'ClosedTickets'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('ClosedTickets')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Closed Tickets</span>
              </Link>
              <Link
                to="/supportticket/answeredtickets"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'AnsweredTickets'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('AnsweredTickets')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Answered Tickets</span>
              </Link>
              <Link
                to="/supportticket/alltickets"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'AllTickets'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('AllTickets')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />All Tickets</span>
              </Link>


            </div>
          )}

          {/* Report */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm  gap-2  p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(TbListDetails, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500  text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'Report' ? 'font-medium' : 'font-thin'}`}

              onClick={() => {
                toggleDropdown('Report'); handleTabSelect('Report');
              }}
            >
              Report

            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'Report' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Report
            </h2>
          </Link>
          {open && openDropdownId === 'Report' && (
            <div className="ms-6 flex flex-col gap-0 w-52 items-end">

              <Link
                to="/report/transactionhistory"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'TransactionHistory'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('TransactionHistory')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Transaction History</span>
              </Link>
              <Link
                to="/report/loginhistory"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'LoginHistory'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('LoginHistory')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Login History</span>
              </Link>
              <Link
                to="/report/notificationhistory"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'NotificationHistory'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('NotificationHistory')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Notification History</span>
              </Link>


            </div>
          )}

          {/* Subscribers */}
          <Link
            to="/subscribers"
            className={`
                 group flex items-center text-sm gap-2  p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(AiOutlineLike, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500 text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                } ${selectedTab === 'Subscribers' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                handleTabSelect('Subscribers');
              }}
            >
              Subscribers
            </button>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Subscribers
            </h2>
          </Link>

          {/* System Settings */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm  gap-2  p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(IoSettingsOutline, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500 text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                }  ${selectedTab === 'SystemSettings' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                toggleDropdown('SystemSettings'); handleTabSelect('SystemSettings');
              }}
            >
              System Settings
            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'SystemSettings' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              System Settings
            </h2>
          </Link>
          {open && openDropdownId === 'SystemSettings' && (
            <div className="ms-6 flex flex-col gap-0 w-48 items-end">

              <Link
                to="/systemsettings/generalsetting"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'GeneralSetting'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('GeneralSetting')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />General Setting </span>
              </Link>
              <Link
                to="/systemsettings/LogoandFavicon"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'LogoandFavicon'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('LogoandFavicon')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Logo & Favicon </span>
              </Link>
              <Link
                to="/systemsettings/systemconfiguration"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'SystemConfiguration'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('SystemConfiguration')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />System Configuration</span>
              </Link>
              <Link
                to="/systemsettings/managefees"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'ManageFees'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('ManageFees')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Manage Fees</span>
              </Link>
              <Link
                to="/systemsettings/licenseinformation"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'LicenseInformation'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('LicenseInformation')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />License Information</span>
              </Link>
              <Link
                to="/systemsettings/storeconfigure"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'StoreConfigure'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => {
                  handleTabSelect('StoreConfigure');
                  toggleStoreConfigureDropdown();
                }}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Store Configure<FaAngleDown /> </span>
              </Link>
              {storeConfigureDropdownOpen && (
                <div className="ps-4 flex flex-col gap-0 w-48 items-end">
                  <Link
                    to="/systemsettings/storeconfigure/ftp"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'FTP'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('FTP')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />FTP</span>
                  </Link>
                  <Link
                    to="/systemsettings/storeconfigure/wasabi"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'Wasabi'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('Wasabi')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />Wasabi</span>
                  </Link>
                  <Link
                    to="/systemsettings/storeconfigure/digitalocean"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'DigitalOcean'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('DigitalOcean')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />Digital Ocean</span>
                  </Link>
                </div>
              )}
              <Link
                to="/systemsettings/authorinformation"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'AuthorInformation'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('AuthorInformation')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Author Information</span>
              </Link>

              <Link
                to="/systemsettings/notificationsetting"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'NotificationSetting'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => {
                  handleTabSelect('NotificationSetting');
                  toggleNotificationSettingsDropdown();
                }}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Notification <FaAngleDown /> </span>
              </Link>
              {notificationSettingsDropdownOpen && (
                <div className="ps-4 flex flex-col gap-0 w-48 items-end">
                  <Link
                    to="/systemsettings/notificationsetting/notificationtemplates"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'NotificationTemplates'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('NotificationTemplates')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />Templates</span>
                  </Link>
                  <Link
                    to="/systemsettings/notificationsetting/globaltemplate"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'GlobalTemplate'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('GlobalTemplate')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />Global Template</span>
                  </Link>
                  <Link
                    to="/systemsettings/notificationsetting/emailsetting"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'EmailSetting'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('EmailSetting')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />Email </span>
                  </Link>
                  <Link
                    to="/systemsettings/notificationsetting/smssettings"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'SMSSettings'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('SMSSettings')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />SMS </span>
                  </Link>
                  <Link
                    to="/systemsettings/notificationsetting/pushnotificationsettings"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'PushNotificationSettings'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('PushNotificationSettings')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />Push Notification</span>
                  </Link>

                </div>
              )}
              <Link
                to="/systemsettings/paymentgateways"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'PaymentGateways'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => {
                  handleTabSelect('PaymentGateways');
                  togglePaymentGatewaysDropdown();
                }}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Payment Gat..<FaAngleDown /> </span>
              </Link>
              {PaymentGatewaysDropdownOpen && (
                <div className="ps-4 flex flex-col gap-0 w-48 items-end">
                  <Link
                    to="/systemsettings/paymentgateways/automaticgateways"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md   ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'AutomaticGateways'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('AutomaticGateways')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />Automatic Gat..</span>
                  </Link>
                  <Link
                    to="/systemsettings/paymentgateways/manualgateways"
                    className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                      } ${selectedTab === 'ManualGateways'
                        ? 'text-Select_text'
                        : 'text-unSelect_text'
                      }`}
                    onClick={() => handleTabSelect('ManualGateways')}
                  >
                    <span className='flex gap-2 items-center'><GoDotFill />Manual Gateways</span>
                  </Link>

                </div>
              )}
              <Link
                to="/systemsettings/withdrawalmethods"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'WithdrawalMethods'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('WithdrawalMethods')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Withdrawal Methods</span>
              </Link>
              <Link
                to="/systemsettings/scoconfiguration"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'SCOConfiguration'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('SCOConfiguration')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />SCO Configuration</span>
              </Link>
              <Link
                to="/systemsettings/managefrontend"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'ManageFrontend'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('ManageFrontend')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Manage Frontend</span>
              </Link>
              <Link
                to="/systemsettings/managepages"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'ManagePages'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('ManagePages')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Manage Pages</span>
              </Link>
              <Link
                to="/systemsettings/maintenancemode"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'MaintenanceMode'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('MaintenanceMode')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Maintenance Mode</span>
              </Link>
              <Link
                to="/systemsettings/gdpr-cookie"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'GDPRCookie'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('GDPRCookie')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />GDPR Cookie</span>
              </Link>
              <Link
                to="/systemsettings/custom-css"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'CustomCSS'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('CustomCSS')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Custom CSS</span>
              </Link>
              <Link
                to="/systemsettings/sitemap-xml"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'SitemapXML'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('SitemapXML')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Sitemap XML</span>
              </Link>
              <Link
                to="/systemsettings/robots-txt"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'RobotsTXT'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('RobotsTXT')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Robots TXT</span>
              </Link>


            </div>
          )}

          {/* Extra */}
          <Link
            to="/dashboard"
            className={`
                 group flex items-center text-sm  gap-2 p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(LiaServerSolid, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500 text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                } ${selectedTab === 'Extra' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                toggleDropdown('Extra'); handleTabSelect('Extra');
              }}
            >
              Extra
            </button>
            <span
              className={`ml-auto text-xl transition-all duration-500 ease-in-out ${open && openDropdownId === 'Extra' ? 'rotate-90' : 'rotate-0'
                }  ${open ? '' : 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              <PiCaretCircleRight className="transition-transform duration-500 ease-in-out" />
            </span>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Extra
            </h2>
          </Link>
          {open && openDropdownId === 'Extra' && (
            <div className="ms-6 flex flex-col gap-0 w-44 items-end">

              <Link
                to="/extra/application"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Application'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Application')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Application </span>
              </Link>
              <Link
                to="/extra/server"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Server'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Server')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Server </span>
              </Link>
              <Link
                to="/extra/Clear"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Clear'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Clear')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Clear</span>
              </Link>
              <Link
                to="/extra/updates"
                className={`w-full px-4 py-2 text-left text-sm hover:text-Select_text drop-shadow-md ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                  } ${selectedTab === 'Updates'
                    ? 'text-Select_text'
                    : 'text-unSelect_text'
                  }`}
                onClick={() => handleTabSelect('Updates')}
              >
                <span className='flex gap-2 items-center'><GoDotFill />Updates </span>
              </Link>

            </div>
          )}

          {/* Report & Request*/}
          <Link
            to="/reportandrequest"
            className={`
                 group flex items-center text-sm gap-2 p-2 hover:bg-[#ff9f43] hover:text-white rounded-md`}
          >
            <div>{React.createElement(IoBugOutline, { size: '25' })}</div>
            <button
              style={{
                transitionDelay: `200ms`,
              }}
              className={`whitespace-pre duration-500 text-lg ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                } ${selectedTab === 'ReportAndRequest' ? 'font-medium' : 'font-thin'}`}
              onClick={() => {
                handleTabSelect('ReportAndRequest');
              }}
            >
              Report & Request

            </button>
            <h2
              className={`${open && 'hidden'
                } absolute left-48 bg-[#1B1212] font-semibold whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Report & Request

            </h2>
          </Link>



        </div>
      </div>


    </section >
  );
}

export default SideBarPage;
