import React, { useState } from 'react';

const CustomCSS = () => {
  const [css, setCss] = useState('');

  const handleChange = (e) => {
    setCss(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted Sitemap XML:', css);
    alert('Sitemap XML has been submitted!');
  };

  // Function to calculate line numbers based on textarea value
  const getLineNumbers = () => {
    return css.split('\n').map((_, index) => index + 1).join('\n');
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Custom CSS</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >
      <div className='bg-gray-50 mx-8 px-4 py-2 mt-2 border rounded-md'>
        <p className='mb-2'>From this page, you can add/update CSS for the user interface. Changing content on this page required programming knowledge.</p>
        <p>Please do not change/edit/add anything without having proper knowledge of it. The website may misbehave due to any mistake you have made.</p>
      </div>
      <div className=" mt-4 gap-4 px-8">
        <h1 className="text-xl font-bold mb-4">Write Custom CSS</h1>
        <form onSubmit={handleSubmit} className="relative">
          <div className="flex">
            {/* Line Numbers */}
            <div
              className="bg-black text-gray-400 rounded-l-lg p-2 text-right select-none"
              style={{ minWidth: '50px' }}
            >
              <pre>{getLineNumbers()}</pre>
            </div>

            {/* Sitemap Editor */}
            <textarea
              value={css}
              onChange={handleChange}
              className="w-full min-h-96 p-2 bg-black text-white border-none focus:outline-none rounded-r-lg resize-none"
              placeholder="Insert your Custom CSS here..."
            />
          </div>

          <button
            type="submit"
            className=" mt-4 bg-Select_text text-white px-4 py-2 rounded-md hover:bg-Select_text2"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default CustomCSS;
