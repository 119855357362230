import React, { useState } from 'react'

function ManageFees() {
  const [personalLicenseFee, setPersonalLicenseFee] = useState(10);
  const [commercialLicenseFee, setCommercialLicenseFee] = useState(20);
  const [extendedFee, setExtendedFee] = useState(12);

  const handleSubmit = () => {
    // Handle form submission logic here, e.g., send data to a backend API
    console.log("Form submitted!");
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Manage Product Fees</h2>
          <p className="text-gray-500">Manage and configure all fee-related settings.</p>
        </div>
      </header >
      <div className="mx-auto p-4">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-8 px-6">
          <div>
            <label htmlFor="personal-license-fee" className="block text-gray-700 font-bold mb-2">
              Buyer Fee (Personal License) *
            </label>
            <div className="flex items-center border bg-gray-200 rounded-lg">
              <span className="text-gray-900 font-bold px-3">$</span>
              <input
                type="number"
                id="personal-license-fee"
                className="shadow appearance-none border-r border-l w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={personalLicenseFee}
                onChange={(e) => setPersonalLicenseFee(parseInt(e.target.value))}
              />
              <span className="text-gray-900 font-bold px-3">USD</span>
            </div>
          </div>
          <div>
            <label htmlFor="commercial-license-fee" className="block text-gray-700 font-bold mb-2">
              Buyer Fee (Commercial License) *
            </label>
            <div className="flex items-center border bg-gray-200 rounded-lg">
              <span className="text-gray-900 font-bold px-3">$</span>
              <input
                type="number"
                id="commercial-license-fee"
                className="shadow appearance-none border-r border-l w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={commercialLicenseFee}
                onChange={(e) => setCommercialLicenseFee(parseInt(e.target.value))}
              />
              <span className="text-gray-900 font-bold px-3">USD</span>
            </div>
          </div>
          <div>
            <label htmlFor="extended-fee" className="block text-gray-700 font-bold mb-2">
              12 Month Extended Fee
            </label>
            <div className="flex items-center border bg-gray-200 rounded-lg">
              <span className="text-gray-900 font-bold px-3">$</span>
              <input
                type="number"
                id="extended-fee"
                className="shadow appearance-none border-r border-l w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={extendedFee}
                onChange={(e) => setExtendedFee(parseInt(e.target.value))}
              />
              <span className="text-gray-900 font-bold px-3">USD</span>
            </div>
          </div>
        </div>

        <button
          className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline mt-4 ms-6"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>

    </>
  )
}

export default ManageFees