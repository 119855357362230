import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { IoSearch } from "react-icons/io5";
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa6';
import { IoMdAddCircleOutline } from "react-icons/io";
import { CgScreen } from "react-icons/cg";
import { PiEyeBold } from "react-icons/pi";

function SoftRejectedProducts() {
  const [data, setData] = useState([
    {
      selected: false,
      productImg: 'https://script.viserlab.com/codeplus/assets/files/product//t-79646/66d6028153b931725301377.png',
      product: 'NewsLab - Online New...',
      purchaseDate: '2024-08-25 12:54 AM',
      authorC: 'ViserLab LLC ',
      authorN: '@username',
      categoryType: 'Ecommercs',
      categoryLang: 'PHP Scripts',
      price: '2.00',
      commercialPrice: '3.00',
      status: 'Soft Rejected',
      rating: '3',
    },

  ]);
  const [showModal, setShowModal] = React.useState(false);
  const [name, setName] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 15; // Maximum number of rows per page
  const totalPages = Math.ceil(data.length / rowsPerPage); // Calculate total pages

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll }))); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected)); // If all checkboxes are selected, set selectAll to true
  };
  // Render stars based on rating
  const renderStars = (rating) => {
    const totalStars = 5;
    return (
      <div className="flex justify-center">
        {[...Array(totalStars)].map((_, index) => (
          <FaStar
            key={index}
            className={`text-lg ${index < rating ? 'text-yellow-400' : 'text-gray-200'} `}
          />
        ))}
      </div>
    );
  };
  // Get current page data
  const currentPageData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility

  const handleAlertButtonClick = () => {
    setShowAlert(true); // Show the alert when the button is clicked
  };

  const handleConfirm = () => {
    //  Execute your unfeature action here
    console.log('Category unfeatured!');
    setShowAlert(false);
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  const navigate = useNavigate();

  // Function to navigate to the Product Details page
  const handleViewDetails = () => {
    navigate(`/manageproducts/productdetails`);
  };


  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Soft Rejected Products</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-[90vw]  border-collapse border">
            <thead className='border-b'>
              <th className='border-b'>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm e">Product</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm e">Upload Date</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Author</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Category</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Sub Category</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Price</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Commercial Price</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Status</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Rating</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Action</th>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-start flex items-center gap-2 justify-start pl-4">
                    <img src={item.productImg} alt="Product" className="w-8 h-8 inline-block rounded-md" />
                    <span className=''>{item.product}</span>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.purchaseDate}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <div className='flex flex-col'>
                      <span className=''>{item.authorC}</span>
                      {item.authorN}
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.categoryType}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.categoryLang}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    $ {item.price}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    $ {item.commercialPrice}
                  </td>
                  <td className="px-4 py-3 text-sm text-start pl-4">
                    <span className='px-5 py-3 rounded-lg bg-red-100 border border-red-600 text-red-600'>{item.status}</span>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">{renderStars(item.rating)}</td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className='flex gap-2 flex-wrap'>
                      <button
                        onClick={() => handleViewDetails()}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      ><CgScreen /></button>
                      <button
                        onClick={handleAlertButtonClick}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#092C4C] hover:bg-[#092C4C] hover:text-white`}
                      ><PiEyeBold />
                      </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">Showing {currentPageData.length} of {data.length} Results</p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${currentPage === index + 1 ? 'bg-orange-400 text-white' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
      {showAlert && (
        <div className="fixed top-0 inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96 mt-10">
            <div className='flex justify-between items-center py-2'>
              <h2 className="text-lg font-bold">Confirmation Alert!</h2>
              <button className="font-bold" onClick={handleCancel}>X</button>
            </div>
            <p className='border-b border-t py-2'>Are you sure to feature this product?</p>
            <div className="flex justify-end mt-4">
              <button className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-4 rounded-lg mr-2" onClick={handleCancel}>No</button>
              <button className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-lg" onClick={handleConfirm}>Yes</button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SoftRejectedProducts