import React, { useState } from 'react';
import { MdOutlineEmail, MdOutlinePhoneAndroid, MdNotificationsNone } from 'react-icons/md';

function GlobalTemplate() {
  const [selectedContent, setSelectedContent] = useState('emailTemplate');

  const handleButtonClick = (template) => {
    setSelectedContent(template);
  };

  // Email Template State
  const [formEmailTemplateData, setFormEmailTemplateData] = useState({
    fromName: '',
    fromEmail: '',
    emailBody: '',
  });

  const handleEmailTemplateInputChange = (e) => {
    const { name, value } = e.target;
    setFormEmailTemplateData({
      ...formEmailTemplateData,
      [name]: value,
    });
  };

  // SMS Template State
  const [formSmsTemplateData, setFormSmsTemplateData] = useState({
    from: '',
    smsBody: '',
  });

  const handleSmsTemplateInputChange = (e) => {
    const { name, value } = e.target;
    setFormSmsTemplateData({
      ...formSmsTemplateData,
      [name]: value,
    });
  };

  // Push Notification Template State
  const [formPushNotificationTemplateData, setFormPushNotificationTemplateData] = useState({
    notificationTitle: '',
    notificationBody: '',
  });

  const handlePushNotificationTemplateInputChange = (e) => {
    const { name, value } = e.target;
    setFormPushNotificationTemplateData({
      ...formPushNotificationTemplateData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form Data:', {
      email: formEmailTemplateData,
      sms: formSmsTemplateData,
      notification: formPushNotificationTemplateData,
    });
  };

  return (
    <>
      {/* ... your other code remains unchanged ... */}

      {/* Form Rendering based on selected template */}
      <div className="my-4 mx-12 rounded-md px-6 py-2 border">
        {selectedContent === 'emailTemplate' && (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            {/* Email Sent From - Name */}
            <div className="flex flex-col">
              <label htmlFor="fromName" className="text-sm font-medium text-gray-700">
                Email Sent From - Name
              </label>
              <input
                type="text"
                id="fromName"
                name="fromName"
                value={formEmailTemplateData.fromName}
                onChange={handleEmailTemplateInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your name"
                required
              />
            </div>

            {/* Email Sent From - Email */}
            <div className="flex flex-col">
              <label htmlFor="fromEmail" className="text-sm font-medium text-gray-700">
                Email Sent From - Email
              </label>
              <input
                type="email"
                id="fromEmail"
                name="fromEmail"
                value={formEmailTemplateData.fromEmail}
                onChange={handleEmailTemplateInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email"
                required
              />
            </div>

            {/* Email Body */}
            <div className="flex flex-col">
              <label htmlFor="emailBody" className="text-sm font-medium text-gray-700">
                Email Body
              </label>
              <textarea
                id="emailBody"
                name="emailBody"
                value={formEmailTemplateData.emailBody}
                onChange={handleEmailTemplateInputChange}
                rows="5"
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your message"
                required
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-min bg-Select_text text-white px-4 py-2 rounded-md hover:bg-Select_text2"
            >
              Submit
            </button>
          </form>
        )}

        {selectedContent === 'smsTemplate' && (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            {/* SMS Sent From */}
            <div className="flex flex-col">
              <label htmlFor="from" className="text-sm font-medium text-gray-700">
                SMS Sent From
              </label>
              <input
                type="text"
                id="from"
                name="from"
                value={formSmsTemplateData.from}
                onChange={handleSmsTemplateInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter sender name or number"
                required
              />
            </div>

            {/* SMS Body */}
            <div className="flex flex-col">
              <label htmlFor="smsBody" className="text-sm font-medium text-gray-700">
                SMS Body
              </label>
              <textarea
                id="smsBody"
                name="smsBody"
                value={formSmsTemplateData.smsBody}
                onChange={handleSmsTemplateInputChange}
                rows="5"
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your SMS message"
                required
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-min bg-Select_text text-white px-4 py-2 rounded-md hover:bg-Select_text2"
            >
              Submit
            </button>
          </form>
        )}

        {selectedContent === 'pushNotificationTemplate' && (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            {/* Notification Title */}
            <div className="flex flex-col">
              <label htmlFor="notificationTitle" className="text-sm font-medium text-gray-700">
                Notification Title
              </label>
              <input
                type="text"
                id="notificationTitle"
                name="notificationTitle"
                value={formPushNotificationTemplateData.notificationTitle}
                onChange={handlePushNotificationTemplateInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter the notification title"
                required
              />
            </div>

            {/* Push Notification Body */}
            <div className="flex flex-col">
              <label htmlFor="notificationBody" className="text-sm font-medium text-gray-700">
                Push Notification Body
              </label>
              <textarea
                id="notificationBody"
                name="notificationBody"
                value={formPushNotificationTemplateData.notificationBody}
                onChange={handlePushNotificationTemplateInputChange}
                rows="5"
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter the notification message"
                required
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-min bg-Select_text text-white px-4 py-2 rounded-md hover:bg-Select_text2"
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </>
  );
}

export default GlobalTemplate;
