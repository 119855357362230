import React, { useState } from 'react';
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { GoShieldCheck } from "react-icons/go";

function Clear() {
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleClearClick = () => {
    setIsAlertVisible(true);
    // Replace this with your actual cache clearing logic
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 3000);
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Clear System Cache</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >
      <div className="mx-auto mt-2 p-4 md:w-3/5 w-full">
        <div className="bg-white shadow-md rounded-md p-6">
          <ul className="text-2xl ml-6">
            <li className='flex gap-2 items-center'><span><IoCheckmarkDoneSharp /></span>Compiled views will be cleared</li>
            <li className='flex gap-2 items-center'><span><IoCheckmarkDoneSharp /></span>Application cache will be cleared</li>
            <li className='flex gap-2 items-center'><span><IoCheckmarkDoneSharp /></span>Route cache will be cleared</li>
            <li className='flex gap-2 items-center'><span><IoCheckmarkDoneSharp /></span>Configuration cache will be cleared</li>
            <li className='flex gap-2 items-center'><span><IoCheckmarkDoneSharp /></span>Compiled services and packages files will be removed</li>
            <li className='flex gap-2 items-center'><span><IoCheckmarkDoneSharp /></span>Caches will be cleared</li>
          </ul>

          <button
            onClick={handleClearClick}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Click to clear
          </button>

          {isAlertVisible && (
            <div className="mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative flex items-center gap-2" role="alert">
              <span className='text-4xl'><GoShieldCheck /></span>
              <div>
                <h1>Succcess!!!</h1>
                <h1>Cache Cleared Successfully</h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Clear;