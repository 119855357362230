import React, { useState } from 'react';

function SMSSettings() {
  // State management for form and selected send method
  const [sendMethod, setSendMethod] = useState('');
  const [formData, setFormData] = useState({
    apiKey: '',
    username: '',
    password: '',
    apiSecret: '',
  });

  // Handle change in dropdown selection
  const handleMethodChange = (e) => {
    setSendMethod(e.target.value);
    // Reset fields when method changes
    setFormData({
      apiKey: '',
      username: '',
      password: '',
      apiSecret: '',
    });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form data submission (e.g., API call)
    console.log('Form Data:', formData);
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">SMS Notification Settings </h2>
          <p className="text-gray-500"></p>
        </div>
      </header>

      <div className="mx-8 my-4 p-4 border rounded-md shadow-md bg-white  ">
        <h2 className="text-2xl font-bold mb-4">SMS Send Method</h2>

        {/* Dropdown for SMS Send Method */}
        <div className="mb-4">
          <label htmlFor="sendMethod" className="text-sm font-medium text-gray-700">
            SMS Send Method
          </label>
          <select
            id="sendMethod"
            name="sendMethod"
            value={sendMethod}
            onChange={handleMethodChange}
            className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
          >
            <option value="">Select Method</option>
            <option value="clickatell">Clickatell</option>
            <option value="infobip">Infobip</option>
            <option value="message">Message</option>
            <option value="nexmo">Nexmo</option>
          </select>
        </div>

        {/* Conditional Forms Based on Send Method */}
        {sendMethod === 'clickatell' && (
          <div className="mb-4">
            <h1 className='font-bold text-lg mt-2'>Clickatell Configuration</h1>
            <label htmlFor="apiKey" className="text-sm font-medium text-gray-700">
              API Key
            </label>
            <input
              type="text"
              id="apiKey"
              name="apiKey"
              value={formData.apiKey}
              onChange={handleInputChange}
              className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
              placeholder="Enter Clickatell API Key"
              required
            />
          </div>
        )}

        {sendMethod === 'infobip' && (
          <div className="flex flex-col gap-4 mb-4">
            <h1 className='font-bold text-lg mt-2'>Infobip Configuration</h1>
            <div>
              <label htmlFor="username" className="text-sm font-medium text-gray-700">
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Infobip Username"
                required
              />
            </div>

            <div>
              <label htmlFor="password" className="text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Infobip Password"
                required
              />
            </div>
          </div>
        )}

        {sendMethod === 'message' && (
          <div className="mb-4">
            <h1 className='font-bold text-lg mt-2'>Mailjet API Configuration</h1>
            <label htmlFor="apiKey" className="text-sm font-medium text-gray-700">
              API Key
            </label>
            <input
              type="text"
              id="apiKey"
              name="apiKey"
              value={formData.apiKey}
              onChange={handleInputChange}
              className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
              placeholder="Enter Message API Key"
              required
            />
          </div>
        )}

        {sendMethod === 'nexmo' && (
          <div className="flex flex-col gap-4 mb-4">
            <h1 className='font-bold text-lg mt-2'>Nexmo Configuration</h1>
            <div>
              <label htmlFor="apiKey" className="text-sm font-medium text-gray-700">
                API Key
              </label>
              <input
                type="text"
                id="apiKey"
                name="apiKey"
                value={formData.apiKey}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Nexmo API Key"
                required
              />
            </div>

            <div>
              <label htmlFor="apiSecret" className="text-sm font-medium text-gray-700">
                API Secret
              </label>
              <input
                type="password"
                id="apiSecret"
                name="apiSecret"
                value={formData.apiSecret}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Nexmo API Secret"
                required
              />
            </div>
          </div>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          onClick={handleSubmit}
          className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-md"
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default SMSSettings;
