import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const GDPRCookie = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [shortDescription, setShortDescription] = useState(
    "We may use cookies or any other tracking technologies when you visit our website, including any other media form, mobile website, or mobile application related or connected to help customize the Site and improve your experience."
  );
  const [description, setDescription] = useState(
    `<h2><strong>What information do we collect?</strong></h2>
     <p>We gather data from you when you register on our site, submit a request, buy any services, react to an overview, or round out a structure. At the point when requesting any assistance or enrolling on our site, as suitable, you might be approached to enter your: name, email address, or telephone number. You may, nonetheless, visit our site anonymously.</p>

     <h2><strong>How do we protect your information?</strong></h2>
     <p>All provided delicate/credit data is sent through Stripe. After an exchange, your private data (credit cards, social security numbers, financials, and so on) won't be put away on our workers.</p>

     <h2><strong>Do we disclose any information to outside parties?</strong></h2>
     <p>We don't sell, exchange, or in any case move to outside gatherings by and by recognizable data. This does exclude confided in outsiders who help us in working our site, leading our business, or adjusting you, since those gatherings consent to keep this data private. We may likewise deliver your data when we accept discharge is suitable to follow the law, implement our site strategies, or ensure our own or others' rights, property, or wellbeing.</p>

     <h2><strong>Children's Online Privacy Protection Act Compliance</strong></h2>
     <p>We are consistent with the prerequisites of COPPA (Children's Online Privacy Protection Act), we don't gather any data from anybody under 13 years old. Our site, items, and administrations are completely coordinated to individuals who are in any event 13 years of age or more established.</p>

     <h2><strong>Changes to our Privacy Policy</strong></h2>
     <p>If we decide to change our privacy policy, we will post those changes on this page.</p>

     <h2><strong>How long we retain your information?</strong></h2>
     <p>At the point when you register for our site, we cycle and keep your information we have about you however long you don't erase the record or withdraw yourself (subject to laws and guidelines).</p>

     <h2><strong>What we don’t do with your data</strong></h2>
     <p>We don't and will never share, unveil, sell, or in any case give your information to different organizations for the promoting of their items or administrations.</p>`
  );

  // Toggle form enable/disable
  const toggleForm = () => {
    setIsDisabled(!isDisabled);
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-4">GDPR Cookie Consent</h2>

      {/* Toggle button for enabling/disabling form */}
      <div className="mb-4">
        <button
          onClick={toggleForm}
          className={`px-4 py-2 font-bold text-white rounded ${isDisabled ? 'bg-green-500' : 'bg-red-500'} hover:opacity-75`}
        >
          {isDisabled ? 'Enable Form' : 'Disable Form'}
        </button>
      </div>

      {/* Short Description Text Area */}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Short Description</label>
        <textarea
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          disabled={isDisabled}
          className="border p-2 w-full"
          rows="4"
        />
      </div>

      {/* Description Text Editor */}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Description</label>
        <ReactQuill
          value={description}
          onChange={setDescription}
          readOnly={isDisabled}
          theme="snow"
        />
      </div>

      {/* Submit Button */}
      <div className="mt-4">
        <button
          type="submit"
          disabled={isDisabled}
          className={`bg-Select_text text-white py-2 px-4 rounded hover:bg-Select_text2 ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default GDPRCookie;
