import React, { useState } from 'react';

const Wasabi = () => {
  const [formData, setFormData] = useState({
    driverName: '',
    apiKey: '',
    secretKey: '',
    region: '',
    bucketName: '',
    endPoint: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    // Add your form submission logic here
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Wasabi Configuration
          </h2>
          <p className="text-gray-500"></p>
        </div>
      </header>
      <div className="py-2">
        <form onSubmit={handleSubmit} className="bg-white border m-4 rounded px-8 pt-6 pb-8 mb-4 grid md:grid-cols-3 grid-cols-1 gap-4 ">


          {/* Driver Name */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="driverName">
              Driver Name
            </label>
            <input
              id="driverName"
              name="driverName"
              type="text"
              value={formData.driverName}
              onChange={handleChange}
              placeholder="Driver Name"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* API Key */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="apiKey">
              API Key
            </label>
            <input
              id="apiKey"
              name="apiKey"
              type="text"
              value={formData.apiKey}
              onChange={handleChange}
              placeholder="Your API Key"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Secret Key */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="secretKey">
              Secret Key
            </label>
            <input
              id="secretKey"
              name="secretKey"
              type="password"
              value={formData.secretKey}
              onChange={handleChange}
              placeholder="Your Secret Key"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Region */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="region">
              Region
            </label>
            <input
              id="region"
              name="region"
              type="text"
              value={formData.region}
              onChange={handleChange}
              placeholder="Region"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Bucket Name */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bucketName">
              Bucket Name
            </label>
            <input
              id="bucketName"
              name="bucketName"
              type="text"
              value={formData.bucketName}
              onChange={handleChange}
              placeholder="Bucket Name"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* End Point */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endPoint">
              End Point
            </label>
            <input
              id="endPoint"
              name="endPoint"
              type="text"
              value={formData.endPoint}
              onChange={handleChange}
              placeholder="End Point URL"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Submit Button */}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Wasabi;
