import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa6';
import { IoMdAddCircleOutline } from "react-icons/io";
import { CgScreen } from "react-icons/cg";

function KYCUnverifiedUsers() {
  const [data, setData] = useState([
    {
      selected: false,
      authorName: 'ViserLab LLC ',
      authorUsername: '@username',
      email: 'demo@gmail.com',
      phone: '+91 45445454545',
      country: 'IN',
      joinedAtTime: '2024-01-23 12:46 PM',
      joinedAtDuration: '7 months ago',
      balance: '00.00',
    },
  ]);
  const [showModal, setShowModal] = React.useState(false);
  const [name, setName] = useState('');

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 15; // Maximum number of rows per page
  const totalPages = Math.ceil(data.length / rowsPerPage); // Calculate total pages

  const [selectAll, setSelectAll] = useState(false);

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll }))); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected)); // If all checkboxes are selected, set selectAll to true
  };
  // Get current page data
  const currentPageData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
    <header className='flex justify-between px-6 py-2 mt-2'>
      <div>
        <h2 className="text-xl font-bold">KYC Unverified Users</h2>
        <p className="text-gray-500"></p>
      </div>
    </header >
    <div className="mx-auto mt-2 p-4 w-[80vw] ">
      <div className="flex justify-between items-center p-4 border-t border-l border-r">
        <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
          <input
            type="text"
            className="w-full px-4 py-2 rounded-md outline-none"
            placeholder="Search..."
          />
          <IoSearch className='text-2xl' />
        </div>
      </div>
      <div className='overflow-x-auto w-full'>
        <table className="table-auto w-full  border-collapse border">
          <thead className='border-b'>
            <th className='border-b'>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
                className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
              />
            </th>
            <th className="px-4 py-4 flex justify-start text-black text-start font-bold uppercase text-sm">User</th>
            <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
              Email
            </th>
            <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
              Phone
            </th>
            <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">	Country</th>
            <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Joined At</th>
            <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Balance</th>
            <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Action</th>
          </thead>
          <tbody>
            {currentPageData.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100 border-b">
                <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => handleRowCheckboxChange(index)}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </td>
                <td className="px-4 py-3 text-md text-start pl-4">
                  <div className='flex items-start'>
                    <div className='flex flex-col'>
                      <span className=''>{item.authorName}</span>
                      {item.authorUsername}
                    </div>
                  </div>
                </td>
                <td className="px-2 py-3 text-md text-start pl-4">{item.email}
                </td>
                <td className="px-2 py-3 text-md text-start pl-4">
                  {item.phone}
                </td>
                <td className="px-2 py-3 text-md text-start pl-4">
                  {item.country}
                </td>
                <td className="px-2 py-3 text-md text-start pl-4">
                  <div className='flex flex-col'>
                    <span>{item.joinedAtTime}</span>
                    {item.joinedAtDuration}
                  </div>
                </td>
                <td className="px-2 py-3 text-md text-start pl-4">$ {item.balance}</td>
                <td className="px-2 py-3 text-sm text-start pl-4">
                  <div className='flex gap-2 flex-wrap'>
                    <button
                      className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                    ><CgScreen />
                    </button>
                  </div>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
        <p className="text-gray-600">Showing {currentPageData.length} of {data.length} Results</p>
        <div className="flex gap-1">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
            <FaAngleLeft />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${currentPage === index + 1 ? 'bg-orange-400 text-white' : ''}`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
            <FaAngleRight />
          </button>
        </div>
      </div>
    </div>

  </>
  )
}

export default KYCUnverifiedUsers