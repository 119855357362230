import React, { useState, useEffect } from 'react';
import { TbDeviceMobileOff } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';

function MobileUnverifiedPage() {
  const [mobileUnverifiedCount, setMobileUnverifiedCount] = useState(2);

  useEffect(() => {
    // Fetch purchase count from API
    fetch('/api/purchase-count')
      .then(response => response.json())
      .then(data => setMobileUnverifiedCount(data.count));
  }, []);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/manageusers/mobileUnverifiedUsers');
  };

  return (
    <div onClick={handleButtonClick} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-yellow-50">
      <div className="flex items-center gap-2">
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-yellow-300 border-l-[3px] text-yellow-500 text-xl">
          <TbDeviceMobileOff />
        </div>
        <div className='flex flex-col'>
          <span className="text-xl font-bold">{mobileUnverifiedCount}</span>
          <span className="text-gray-500">Mobile Unverified User</span>
        </div>
      </div>
    </div>
  );
}

export default MobileUnverifiedPage