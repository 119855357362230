import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineUserGroup } from "react-icons/hi2";

export default function TotalUsers() {
  const [totalUsers, setTotalUsers] = useState(486);

  useEffect(() => {
    // Fetch purchase count from API
    fetch('/api/purchase-count')
      .then(response => response.json())
      .then(data => setTotalUsers(data.count));
  }, []);

  const navigate = useNavigate(); 

  const handleButtonClick = () => {
    navigate('/manageusers/allusers'); 
  };

  return (
    <div
      onClick={handleButtonClick}
      className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-green-50">
      <div className="flex items-center gap-2">
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-green-300 border-l-[3px] text-green-500 text-xl ">
          <HiOutlineUserGroup />
        </div>
        <div className='flex flex-col'>
          <span className="text-xl font-bold">{totalUsers}</span>
          <span className="text-gray-500">Total Users</span>
        </div>
      </div>
    </div>
  )
}
