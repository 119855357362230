import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { IoMdAddCircleOutline } from "react-icons/io";
import { LiaFilterSolid } from "react-icons/lia";
import { BsGrid1X2 } from "react-icons/bs";
import { MdOutlineShortText } from "react-icons/md";

function RefundedItems() {
  const [data, setData] = useState([
    {
      selected: false,
      productImg: 'https://script.viserlab.com/codeplus/assets/files/product//newslab-online-newspaper-and-magazine-platform-86594/65bf8011bb3271707048977.png',
      product: 'NewsLab - Online New...',
      purchaseDate: '2024-08-25 12:54 AM',
      authorImg: 'https://script.viserlab.com/codeplus/assets/images/user/65bb98ad01a3e1706793133.png',
      authorC: 'ViserLab LLC ',
      authorN: 'username',
      buyerImg: 'https://script.viserlab.com/codeplus/assets/images/logo_icon/favicon.png',
      buyerC: 'Erna Eligon ',
      buyerN: 'tronvego',
      purchaseCode: '388221-88-vf13d7-100064',
      productPrice: '39.00',
      sallerFee: '9.75',
      buyerFee: '10.00',
      refunded: 'Yes'
    },

  ]);
  const [showModal, setShowModal] = React.useState(false);
  const [name, setName] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 15; // Maximum number of rows per page
  const totalPages = Math.ceil(data.length / rowsPerPage); // Calculate total pages

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll }))); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected)); // If all checkboxes are selected, set selectAll to true
  };
  // Get current page data
  const currentPageData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Refund Items</h2>
        </div>
      </header >
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-[95vw]  border-collapse border">
            <thead className='border-b'>
              <th className='border-b'>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm e">Product</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm e">Purchase Date</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Author</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Buyer</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Purchase Code</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Product Price</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Seller Fee</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Buyer Fee</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Refunded</th>

            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-4 py-3 text-md text-start flex items-center mt-2 gap-2">
                    <img src={item.productImg} alt="Product" className="w-8 h-8 inline-block mr-2 rounded-md" />
                    <span className=''>{item.product}</span>
                  </td>
                  <td className="px-4 py-3 text-md text-start">{item.purchaseDate}</td>
                  <td className="px-4 py-3 text-md text-start">
                    <div className='flex items-center gap-2'>
                      <img src={item.authorImg} alt="Product" className="w-8 h-8 inline-block mr- rounded-md " />
                      <div className='flex flex-col'>
                        <span className=''>{item.authorC}</span>
                        {item.authorN}
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    <div className='flex items-center gap-2'>
                      <img src={item.buyerImg} alt="Product" className="w-8 h-8 inline-block mr-2 rounded-md" />
                      <div className='flex flex-col'>
                        <span className=''>{item.buyerC}</span>
                        {item.buyerN}
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-md text-start">{item.purchaseCode}</td>
                  <td className="px-4 py-3 text-md text-start">$ {item.productPrice}</td>
                  <td className="px-4 py-3 text-md text-start">$ {item.sallerFee}</td>
                  <td className="px-4 py-3 text-md text-start">$ {item.buyerFee}</td>
                  <td className="px-4 py-3 text-sm text-start">
                    <button
                      className={`px-3 py-3 rounded-lg text-md font-medium ${item.refunded === 'Yes' ? 'border border-green-500 bg-green-100 text-green-600' : 'border border-red-500 bg-red-100 text-red-600'
                        }`}
                    >
                      {item.refunded}
                    </button>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">Showing {currentPageData.length} of {data.length} Results</p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${currentPage === index + 1 ? 'bg-orange-400 text-white' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>

    </>
  )
}

export default RefundedItems