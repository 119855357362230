import { useState } from 'react';

function BanSection() {
  const [title, setTitle] = useState('Oops !');
  const [heading, setHeading] = useState('Your account is currently suspended.');
  const [subheading, setSubheading] = useState('Your account is temporarily suspended. If you have any concerns, please reach out to our support team for prompt assistance & resolution.');
  const [image, setImage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here, e.g., send data to a backend API
    console.log('Form submitted:', { title, heading, subheading, image });
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <div className="bg-white px-8 py-4 rounded-md ">
      <h2 className="text-xl font-bold mb-4">Ban Section</h2>
      <form onSubmit={handleSubmit} className="border rounded-md">
        <div className=" px-8 py-2">
          <label htmlFor="title" className="block text-gray-700 font-bold mb-2">
            Title *
          </label>
          <input
            type="text"
            id="title"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="heading" className="block text-gray-700 font-bold mb-2">
            Heading *
          </label>
          <input
            type="text"
            id="heading"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="subheading" className="block text-gray-700 font-bold mb-2">
            Subheading *
          </label>
          <input
            type="text"
            id="subheading"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={subheading}
            onChange={(e) => setSubheading(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="image" className="block text-gray-700 font-bold mb-2">
            Image
          </label>
          <input
            type="file"
            id="image"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            onChange={handleImageChange}
          />
          <p className="text-gray-600 text-sm mt-1">
            Supported Files: .png, .jpg, .jpeg. Image will be resized into 110x110px
          </p>
        </div>
        <div className='px-8 py-4'>
          <button
            type="submit"
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default BanSection;
