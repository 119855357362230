import React, { useState } from 'react';

const RobotsTXT = () => {
  const [robotsTxt, setRobotsTxt] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle the submission logic here
    console.log('Submitted Robots.txt:', robotsTxt);
    alert('Robots.txt has been submitted!');
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Robots TXT</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >

      <div className=" mt-4 gap-4 px-8">
        <h1 className="text-2xl font-bold mb-4">Insert Robots.txt</h1>
        <form onSubmit={handleSubmit}>
          <textarea
            value={robotsTxt}
            onChange={(e) => setRobotsTxt(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md mb-4 h-48 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Insert your Robots.txt content here..."
          />
          <button
            type="submit"
            className=" bg-Select_text text-white px-4 py-2 rounded-md hover:bg-Select_text2"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default RobotsTXT;
