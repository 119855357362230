import React, { useState } from 'react';
import { MdOutlineEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";

function AuthorInformation() {
  const [authors, setAuthors] = useState([
    {
      name: 'Do you have any team?',
      type: 'Radio',
      width: '100%',
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [editingAuthor, setEditingAuthor] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null); // Track the selected index

  const handleAddAuthor = () => {
    setAuthors([
      ...authors,
      {
        name: 'Do you have any team?',
        type: 'Radio',
        width: '100%',
      },
    ]);
  };

  const handleDeleteAuthor = (index) => {
    setAuthors(authors.filter((_, i) => i !== index));
  };

  const handleEditAuthor = (index) => {
    setEditingAuthor(authors[index]); // Set the author being edited
    setSelectedIndex(index); // Set the selected index
    setShowModal(true); // Open the modal
  };

  const handleSaveEdit = () => {
    const updatedAuthors = [...authors];
    updatedAuthors[selectedIndex] = editingAuthor; // Update the author at the selected index
    setAuthors(updatedAuthors); // Update the authors state

    // Close the modal and reset the editing state
    setShowModal(false);
    setEditingAuthor(null);
    setSelectedIndex(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingAuthor(null);
    setSelectedIndex(null);
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Author Information Setting</h2>
          <p className="text-gray-500">Manage author information and settings.</p>
        </div>
      </header>

      <div className="m-4 border rounded-lg">
        <div className='flex flex-wrap gap-4 md:gap-0 items-center justify-between px-8 py-2 bg-blue-200 rounded-t-lg'>
          <h2 className="text-2xl font-bold">Author Information</h2>
          <div className="bg-Select_text2 text-white font-bold p-2 rounded-md">
            <button onClick={handleAddAuthor}>+ Add New</button>
          </div>
        </div>

        {authors.map((author, index) => (
          <div key={index} className="grid md:grid-cols-5 grid-cols-1 gap-4 border-b px-8 py-2">
            <div className="col-span-1">
              <h3 className="text-xl font-bold">Name</h3>
              <span>{author.name}</span>
            </div>
            <div className="col-span-1">
              <h3 className="text-xl font-bold">Type</h3>
              <span>{author.type}</span>
            </div>
            <div className="col-span-1">
              <h3 className="text-xl font-bold">Width</h3>
              <span>{author.width}</span>
            </div>
            <div className="col-span-1 mt-3">
              <span className="px-5 py-3 rounded-lg bg-green-100 border border-green-600 text-green-600">Required</span>
            </div>
            <div className="col-span-1 flex items-center gap-4">
              <button
                onClick={() => handleEditAuthor(index)}
                className="px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white"
              >
                <MdOutlineEdit />
              </button>
              <button
                onClick={() => handleDeleteAuthor(index)}
                className="px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#FF0000] hover:bg-[#FF0000] hover:text-white"
              >
                <MdDeleteOutline />
              </button>
            </div>
          </div>
        ))}
        <div className="px-8 py-2">
          <button className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline" type="submit">
            Submit
          </button>
        </div>
      </div>

      {/* Modal for editing authors */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96">
            <h2 className="text-xl font-bold mb-4">Edit Author</h2>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name:</label>
              <input
                type="text"
                id="name"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={editingAuthor.name}
                onChange={(e) =>
                  setEditingAuthor({ ...editingAuthor, name: e.target.value })
                }
              />
            </div>
            <div className="mb-4">
              <label htmlFor="type" className="block text-gray-700 font-bold mb-2">Type:</label>
              <select
                id="type"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={editingAuthor.type}
                onChange={(e) =>
                  setEditingAuthor({ ...editingAuthor, type: e.target.value })
                }
              >
                <option value="Radio">Radio</option>
                <option value="Checkbox">Checkbox</option>
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="width" className="block text-gray-700 font-bold mb-2">Width:</label>
              <input
                type="number"
                id="width"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={editingAuthor.width}
                onChange={(e) =>
                  setEditingAuthor({ ...editingAuthor, width: e.target.value })
                }
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleCloseModal}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveEdit}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AuthorInformation;
