import React, { useState } from 'react';
import { FaCheck } from "react-icons/fa6";
import { FaBan } from "react-icons/fa6";
const data = {
  date: "22024-07-28 04:10 PM",
  trxNumber: "29VEFRBJTUCG",
  username: "@username",
  method: "Bank Transfer",
  amount: "100.00",
  charge: "3.00",
  afterCharge: "97.00",
  ratefrom: "1",
  rateTo: "1.00",
  afterRateConversion: "97.00",
  status: "Pending",
  bankName: "des as",
  accountNumber: "a sd asd",
  accountName: "asdasd"
};

const WithdrawalsDetails = () => {
  const [showApproveAlert, setShowApproveAlert] = useState(false);

  const handleApproveAlertButtonClick = () => {
    setShowApproveAlert(true);
  };

  const handleConfirm = () => {
    console.log('Category unfeatured!');
    setShowApproveAlert(false);
  };

  const handleCancel = () => {
    setShowApproveAlert(false);
  };

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [remark, setRemark] = useState("");

  const handleRejectOpenModal = () => {
    setIsRejectModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsRejectModalOpen(false);
    setRemark("");
  };

  return (
    <>
      <header className='px-8 py-4'>
        <h1 className='text-lg font-semibold'>Withdrawals Details</h1>
      </header>
      <div className="flex md:flex-row flex-col bg-white rounded-lg overflow-hidden px-8 py-4 border mx-8">
        <div className="w-1/3 p-8 bg-gray-50 rounded-lg">
          <h1 className='font-semibold border-b pb-2'>User Withdraw Information</h1>
          <div className='mt-4'>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Bank Name</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.bankName}</dd>
            </div>
            <div className='border-b pb-2 mt-4'>
              <dt className="text-sm font-medium text-gray-500">Account Number</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.accountNumber}</dd>
            </div>
            <div className='border-b pb-2 mt-4'>
              <dt className="text-sm font-medium text-gray-500">Account Name</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.accountName}</dd>
            </div>
          </div>
          <div className='flex flex-wrap gap-4 mt-4'>
            <button onClick={handleApproveAlertButtonClick} className='bg-green-50 border-green-500 text-green-400 hover:bg-green-500 hover:text-white font-semibold h-min p-2 text-sm border rounded-lg'><span className='flex items-center gap-2'><FaCheck />Approve</span></button>
            <button onClick={() => handleRejectOpenModal()} className='bg-red-50 border-red-500 text-red-400 hover:bg-red-500 hover:text-white font-semibold h-min p-2 text-sm border rounded-lg'><span className='flex items-center gap-2'><FaBan />Reject</span></button>

          </div>
        </div>
        <div className="w-2/3 p-8 bg-gray-50 rounded-lg">
          <h1 className='font-semibold border-b pb-2'>Withdraw Via Bank Transfer</h1>
          <dl className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-8 mt-4">
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Date</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.date}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">TRX Number</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.trxNumber}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Username</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.username}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Method</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.method}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Amount</dt>
              <dd className="mt-1 text-sm text-gray-900">${data.amount}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Charge</dt>
              <dd className="mt-1 text-sm text-gray-900">${data.charge}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">After Charge</dt>
              <dd className="mt-1 text-sm text-gray-900">${data.afterCharge}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Rate</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.ratefrom} USD = {data.rateTo} USD</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">After Rate Conversion</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.afterRateConversion} USD</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className={`mt-1 text-sm border rounded-md w-min px-4 ${data.status === 'Pending'
                ? 'bg-yellow-50 border-yellow-600 text-yellow-600'
                : data.status === 'Approved'
                  ? 'bg-green-50 border-green-600 text-green-600'
                  : data.status === 'Rejected'
                    ? 'bg-red-50 border-red-600 text-red-600'
                    : ''
                }`}>
                {data.status}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {showApproveAlert && (
        <div className="fixed top-0 inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg px-4 py-2 w-96 mt-10">
            <div className='flex justify-between items-center py-2'>
              <h2 className="text-lg font-semibold">Approve Withdrawal Confirmation</h2>
              <button className="font-semibold hover:bg-red-50 p-1" onClick={handleCancel}>X</button>
            </div>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700">Have you sent 97.00 USD?</label>
                <textarea
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                  required
                  placeholder='Provide the details.'
                ></textarea>
              </div>
              <div className="flex justify-end mt-4">
                <button className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-4 rounded-lg mr-2" onClick={handleCancel}>No</button>
                <button className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-lg" onClick={handleConfirm}>Yes</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* reject*/}
      {isRejectModalOpen && (
        <div className="fixed top-0 inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg px-4 py-2 w-96 mt-10">
            <div className='flex items-center justify-between'>
              <h3 className="text-xl font-semibold">Reject Withdrawal Confirmation</h3>
              <button
                type="button"
                className="font-semibold"
                onClick={handleCloseModal}
              >
                X
              </button>
            </div>
            <form>
              <div className="mb-4 mt-2">
                <label className="block text-gray-700">Reason for Rejection</label>
                <textarea
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                  required
                  placeholder=''
                ></textarea>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default WithdrawalsDetails;