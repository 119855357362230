import React, { useState } from 'react'
import { FaChevronDown } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { BiLogOutCircle } from "react-icons/bi";
import { FaEarthAmericas } from "react-icons/fa6";
import { IoNotificationsOutline } from "react-icons/io5";
import { FiTool } from "react-icons/fi";
function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className='shadow-md px-6 py-2 flex justify-end gap-4 items-center sticky top-0 bg-white z-50'>
      <div className='flex items-center text-black gap-4'>
        <button className='bg-gray-100 px-2 rounded-lg  text-xl w-10 h-10'><FaEarthAmericas /></button>
        <button className='relative bg-gray-100 px-2 rounded-lg  text-xl w-10 h-10'><IoNotificationsOutline />
          <span class="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-Select_text text-white text-xs rounded-full px-2 py-1">
            3
          </span></button>
        <button className='bg-gray-100 px-2 rounded-lg  text-xl w-10 h-10'><FiTool /></button>
        <div className="relative inline-block text-left">
          <button
            onClick={toggleDropdown}
            className="flex items-center gap-2 px-4 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-100"
          >
            <img
              src="https://dreamspos.dreamstechnologies.com/angular/template/assets/img/profiles/avator1.jpg"
              alt="Profile Picture"
              className="w-10 h-10 rounded-lg border"
            />
            <div className='flex flex-col'>
              <span className="ml-2">John Smilga</span>
              <span className="ml-2">Admin</span>
            </div>
            <FaChevronDown
              className={`transition-transform duration-300 ${isOpen ? "rotate-180" : ""
                }`}
            />
          </button>

          {isOpen && (
            <div
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  <span className='flex items-center gap-1'><FiUser /> My Profile</span>
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  <span className='flex items-center gap-1'><IoSettingsOutline /> Settings</span>
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  <span className='flex items-center gap-1'><BiLogOutCircle /> Logout</span>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header