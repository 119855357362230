import React, { useState, useEffect } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

export default function RejectedWithdrawal() {
  const [rejectedWithdrawalCount, setRejectedWithdrawalCount] = useState(1);

  useEffect(() => {
    // Fetch purchase count from API
    fetch('/api/purchase-count')
      .then(response => response.json())
      .then(data => setRejectedWithdrawalCount(data.count));
  }, []);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/withdrawals/rejectedwithdrawals');
  };


  return (
    <div onClick={handleButtonClick} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-red-50">
      <div className="flex items-center gap-2">
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-red-300 border-l-[3px] text-red-500 text-xl">
          <MdOutlineCancel />
        </div>
        <div className='flex flex-col'>
          <span className="text-xl font-bold">{rejectedWithdrawalCount}</span>
          <span className="text-gray-500">Rejected Withdrawals</span>
        </div>
      </div>
    </div>
  )
}
