import React, { useState } from 'react';
import { MdAdd, MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { LiaFilterSolid } from "react-icons/lia";
import { BsGrid1X2 } from "react-icons/bs";
import { MdOutlineShortText } from "react-icons/md";

const BenefitSection = () => {
  const [rotateText, setRotateText] = useState("Best Digital MarketPlace");
  const [title, setTitle] = useState("Additional Benefits");
  const [subtitle, setSubtitle] = useState("In addition to the purchase item, all members get exclusive discounts from top digital companies on the market.");
  const [showModal, setShowModal] = useState(false); // Modal state
  const [image, setImage] = useState(null);
  const [iconImage, setIconImage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted with:", rotateText, title, subtitle, image, iconImage);
  };
  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleIconImageChange = (event) => {
    setIconImage(event.target.files[0]);
  };
  // Modal form data and handling
  const [modalRotateText, setModalRotateText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");

  const handleModalSubmit = (event) => {
    event.preventDefault();
    // Update the main form with the modal input values
    setRotateText(modalRotateText);
    setTitle(modalTitle);
    setSubtitle(modalSubtitle);
    setShowModal(false); // Close the modal
  };

  // Function to open the modal
  const openModal = () => {
    setModalRotateText(rotateText);
    setModalTitle(title);
    setModalSubtitle(subtitle);
    setShowModal(true);
  };

  const [data, setData] = useState([
    {
      selected: false,
      sl: '1',
      icon: '',
      title: "Custom Service"
    },
    {
      selected: false,
      sl: '2',
      icon: '',
      title: "CodePlus Exclusive"
    },
  ]);

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected));
  };
  return (
    <>
      <div className="px-8 py-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold mb-4">Benefit Section</h2>
          <button onClick={openModal} className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-center flex items-center gap-2">
            <MdAdd />Add
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="px-8 py-2">
            <label htmlFor="rotate-text" className="block text-gray-700 font-bold mb-2">
              Rotate text *
            </label>
            <input
              type="text"
              id="rotate-text"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={rotateText}
              onChange={(e) => setRotateText(e.target.value)}
            />
          </div>
          <div className="px-8 py-2">
            <label htmlFor="title" className="block text-gray-700 font-bold mb-2">
              Title *
            </label>
            <input
              type="text"
              id="title"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="px-8 py-2">
            <label htmlFor="subtitle" className="block text-gray-700 font-bold mb-2">
              Subtitle *
            </label>
            <input
              type="text"
              id="subtitle"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />
          </div>
          <div className="flex flex-wrap md:flex-nowrap gap-16 px-8 py-2">
            <div className="mb-4 md:w-1/2 w-full">
              <label htmlFor="image" className="block text-gray-700 font-bold mb-2">
                Image
              </label>
              <input
                type="file"
                id="image"
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageChange}
              />
              <p className="text-gray-600 text-sm mt-1">
                Supported Files: .png, .jpg, .jpeg. Image will be resized into
                635x470px
              </p>
            </div>
            <div className="mb-4 md:w-1/2 w-full">
              <label htmlFor="icon-image" className="block text-gray-700 font-bold mb-2">
                Icon image
              </label>
              <input
                type="file"
                id="icon-image"
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleIconImageChange}
              />
              <p className="text-gray-600 text-sm mt-1">
                Supported Files: .png, .jpg, .jpeg. Image will be resized into
                30x30px
              </p>
            </div>
          </div>
          <div className="px-8 py-2">
            <button
              type="submit"
              className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-full  border-collapse border">
            <thead className='border-b'>
              <th className='border-b'>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">SL</th>

              <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Icon</th>
              <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Title</th>

              <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Action</th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-center ">
                    <span className='text-tabledata_highlighted_text font-bold'>{item.sl}</span>
                  </td>

                  <td className="px-2 py-3 text-md text-center">{item.icon}</td>
                  <td className="px-2 py-3 text-md text-center">{item.title}</td>

                  <td className="px-2 py-3 text-sm text-center">
                    <div className='flex gap-2 justify-center flex-wrap'>

                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      ><MdOutlineEdit />
                      </button>
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#FF0000] hover:bg-[#FF0000] hover:text-white`}
                      ><MdDeleteOutline />
                      </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal Implementation */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
            <h2 className="text-xl font-bold mb-4">Add Benefit Details</h2>
            <form onSubmit={handleModalSubmit}>
              <div className="mb-4">
                <label htmlFor="modal-rotate-text" className="block text-gray-700 font-bold mb-2">
                  Rotate text
                </label>
                <input
                  type="text"
                  id="modal-rotate-text"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={modalRotateText}
                  onChange={(e) => setModalRotateText(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="modal-title" className="block text-gray-700 font-bold mb-2">
                  Title
                </label>
                <input
                  type="text"
                  id="modal-title"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={modalTitle}
                  onChange={(e) => setModalTitle(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="modal-subtitle" className="block text-gray-700 font-bold mb-2">
                  Subtitle
                </label>
                <input
                  type="text"
                  id="modal-subtitle"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={modalSubtitle}
                  onChange={(e) => setModalSubtitle(e.target.value)}
                />
              </div>
              <div className="flex justify-between">
                <button type="submit" className="bg-Select_text text-white font-bold py-2 px-4 rounded">
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="bg-red-500 text-white font-bold py-2 px-4 rounded"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default BenefitSection;
