import { useState } from 'react';

function LatestProductSection() {
  const [title, setTitle] = useState('Check Out Latest Item');
  const [subtitle, setSubtitle] = useState('We carefully review new entries form our community on by one to make sure they meet hight-quality design and functionality standards.');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here, e.g., send data to a backend API
    console.log('Form submitted:', { title, subtitle });
  };

  return (
    <div className="bg-white px-8 py-4 rounded-md ">
      <h2 className="text-xl font-bold mb-4">Latest Product Section</h2>
      <form onSubmit={handleSubmit} className="border rounded-md">
        <div className=" px-8 py-2">
          <label htmlFor="title" className="block text-gray-700 font-bold mb-2">
            Title *
          </label>
          <input
            type="text"
            id="title"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="subtitle" className="block text-gray-700 font-bold mb-2">
            subtitle *
          </label>
          <input
            type="text"
            id="subtitle"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
          />
        </div>

        <div className='px-8 py-4'>
          <button
            type="submit"
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default LatestProductSection;
