import React from "react";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,  // Register the PointElement for line chart points
} from 'chart.js';

// Register the required components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,  // Important for line charts
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const ChartsPage = () => {
  // Data for Bar chart (Deposit & Withdraw Report)
  const barData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Deposit',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(75,192,192,1)',
        data: [6500, 5900, 8000, 8100, 5600, 5500],
      },
      {
        label: 'Withdraw',
        backgroundColor: 'rgba(255,99,132,1)',
        borderColor: 'rgba(255,99,132,1)',
        data: [3000, 4000, 3200, 2900, 3800, 3200],
      },
    ],
  };

  // Data for Line chart (Transactions Report)
  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Transactions',
        data: [1000, 1900, 3000, 5000, 2000, 3000],
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 1)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
      },
    ],
  };

  // Data for Doughnut charts (Login by Browser, OS, Country)
  const doughnutDataBrowser = {
    labels: ['Chrome', 'Firefox', 'Safari', 'Edge', 'Other'],
    datasets: [
      {
        label: 'Logins',
        data: [45, 25, 15, 10, 5],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  const doughnutDataOS = {
    labels: ['Windows', 'macOS', 'Linux', 'Android', 'iOS'],
    datasets: [
      {
        label: 'Logins',
        data: [50, 20, 10, 15, 5],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  const doughnutDataCountry = {
    labels: ['USA', 'India', 'UK', 'Germany', 'Canada'],
    datasets: [
      {
        label: 'Logins',
        data: [40, 30, 10, 15, 5],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* Bar chart and Line chart */}
        <div className="p-4 bg-white shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Deposit & Withdraw Report</h2>
          <Bar data={barData} />
        </div>
        <div className="p-4 bg-white shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Transactions Report</h2>
          <Line data={lineData} />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Doughnut charts for Login by Browser, OS, and Country */}
        <div className="p-4 bg-white shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Login By Browser (Last 30 days)</h2>
          <Doughnut data={doughnutDataBrowser} />
        </div>
        <div className="p-4 bg-white shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Login By OS (Last 30 days)</h2>
          <Doughnut data={doughnutDataOS} />
        </div>
        <div className="p-4 bg-white shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Login By Country (Last 30 days)</h2>
          <Doughnut data={doughnutDataCountry} />
        </div>
      </div>
    </div>
  );
};

export default ChartsPage;
