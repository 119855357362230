import React, { useState } from 'react';
import Select from 'react-select';

const SCOConfiguration = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [metaKeywords, setMetaKeywords] = useState([]);
  const [metaDescription, setMetaDescription] = useState('Unlock the potential of your digital marketplace with CodePlus - more than just a platform, it will act as the powerhouse of your digital product marketplace. Dive into a dynamic marketplace where creators trade in code, templates, and beyond. Your journey begins here at CodePlus, where innovation seamlessly merges with opportunity.');
  const [socialTitle, setSocialTitle] = useState('CodePlus - Code And Template Marketplace');
  const [socialDescription, setSocialDescription] = useState('Unlock the potential of your digital marketplace with CodePlus - more than just a platform, it will act as the powerhouse of your digital product marketplace. Dive into a dynamic marketplace where creators trade in code, templates, and beyond. Your journey begins here at CodePlus, where innovation seamlessly merges with opportunity.');

  // Options for the Meta Keywords dropdown
  const keywordOptions = [
    { value: 'digital product', label: 'Digital Product' },
    { value: 'digital items', label: 'Digital Items' },
    { value: 'buy', label: 'Buy' },
    { value: 'sell', label: 'Sell' },
    { value: 'market place', label: 'Market Place' },
  ];

  // Handle Image Upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    alert('SEO Configuration Submitted!');
  };
  // Check if the form is ready to be submitted
  const isFormValid = metaKeywords.length > 0 && metaDescription && socialTitle && socialDescription;

  return (
    <div className="px-8 py-4">
      <h2 className="text-2xl font-bold mb-4">SEO Configuration</h2>

      <div className="flex flex-col md:flex-row gap-8">
        {/* Left Side: Image Upload */}
        <div className="w-full md:w-1/3">
          <label className="block text-gray-700 text-sm font-bold mb-2">Upload Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="border p-2 w-full"
          />
          {imagePreview && (
            <div className="mt-4">
              <img src={imagePreview} alt="Preview" className="w-full border" />
            </div>
          )}
        </div>

        {/* Right Side: Meta Keywords Dropdown */}
        <div className="w-full">
          <label className="block text-gray-700 text-sm font-bold mb-2">Meta Keywords</label>
          <Select
            isMulti
            value={metaKeywords}
            onChange={setMetaKeywords}
            options={keywordOptions}
            placeholder="Select Meta Keywords"
            className="mb-4"
          />
          <p className="text-gray-500 text-sm mb-4">
            Separate multiple keywords by commas or enter key
          </p>

          {/* Meta Description */}
          <label className="block text-gray-700 text-sm font-bold mb-2">Meta Description</label>
          <textarea
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
            rows="3"
            className="border p-2 w-full mb-4"
          />

          {/* Social Title */}
          <label className="block text-gray-700 text-sm font-bold mb-2">Social Title</label>
          <input
            type="text"
            value={socialTitle}
            onChange={(e) => setSocialTitle(e.target.value)}
            className="border p-2 w-full mb-4"
          />

          {/* Social Description */}
          <label className="block text-gray-700 text-sm font-bold mb-2">Social Description</label>
          <textarea
            value={socialDescription}
            onChange={(e) => setSocialDescription(e.target.value)}
            rows="3"
            className="border p-2 w-full"
          />

          <button
            onClick={handleSubmit}
            disabled={!isFormValid}
            className={`bg-Select_text text-white py-2 px-4 mt-4 rounded hover:bg-Select_text2 ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''
              }`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SCOConfiguration;
