import React, { useState, useEffect } from 'react';
import { GiMoneyStack } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';

export default function TotalPayment() {
  const [totalPaymentCount, setTotalPaymentCount] = useState("43,770.00");

  useEffect(() => {
    // Fetch purchase count from API
    fetch('/api/purchase-count')
      .then(response => response.json())
      .then(data => setTotalPaymentCount(data.count));
  }, []);

  const navigate = useNavigate(); 
  const handleButtonClick = () => {
    navigate('/payments/allpayments'); 
  };

  return (
    <div onClick={handleButtonClick}  className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-green-50">
      <div className="flex items-center gap-2">
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-green-300 border-l-[3px] text-green-500 text-xl">
          <GiMoneyStack />
        </div>
        <div className='flex flex-col'>
          <span className="text-xl font-bold">$ {totalPaymentCount}</span>
          <span className="text-gray-500">Total Payment</span>
        </div>
      </div>
    </div>
  )
}
