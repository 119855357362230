import React from 'react';

const data = {
  category: 'PHP Scripts',
  productImg: "https://script.viserlab.com/codeplus/assets/files/product//t-79646/66d6028153b931725301377.png",
  subcategory: 'Ecommerce',
  regularPrice: '$2.00',
  commercialPrice: '$3.00',
  status: 'Pending',
  demoLink: 'https://viserlab.com/category/php-scripts?page=2',
  filesIncluded: 'PHP',
  tags: ['viserlab'],
};

const ProductDetails = () => {
  return (
    <>
      <header className='px-8 py-4'>
        <h1 className='text-lg font-semibold'>Product Details</h1>
      </header>
      <div className="flex flex-wrap bg-white rounded-lg overflow-hidden px-8 py-4 border mx-8">
        <div className="w-1/3 bg-gray-50 p-8 flex items-center justify-center">
          {/* Replace with actual image URL */}
          <img src={data.productImg} alt="Product" className="inline-block rounded-md" />
        </div>
        <div className="w-2/3 p-8">
          <dl className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-8">
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Category</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.category}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Subcategory</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.subcategory}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Price (Regular License)</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.regularPrice}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Price (Commercial License)</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.commercialPrice}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm 
                  {data.status === 'Pending' ? 'text-yellow-500' : 'text-green-500'}">
                {data.status}
              </dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Demo Link</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <a href={data.demoLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {data.demoLink}
                </a>
              </dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Files Included</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.filesIncluded}</dd>
            </div>
            <div className='border-b pb-2'>
              <dt className="text-sm font-medium text-gray-500">Tags</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.tags.map((tag, index) => (
                  <span key={index} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                    {tag}
                  </span>
                ))}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;