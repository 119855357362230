import React, { useState } from "react";
import Select from "react-select";
import { timezones } from "./timezones";
import { SketchPicker } from "react-color";

function GeneralSetting() {

  // State to hold the site title
  const [siteTitle, setSiteTitle] = useState("CodePlus");

  // Function to handle input change
  const handleSiteTitleInputChange = (event) => {
    setSiteTitle(event.target.value);
  };
  // State to hold the currency and currency symbol
  const [currency, setCurrency] = useState("USD");
  const [currencySymbol, setCurrencySymbol] = useState("$");

  // Function to handle changes in the currency input
  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value.toUpperCase()); // Ensure currency code is uppercase
  };

  // Function to handle changes in the currency symbol input
  const handleCurrencySymbolChange = (event) => {
    setCurrencySymbol(event.target.value);
  };

  const timezoneOptions = timezones.map((tz) => ({
    value: tz,
    label: tz,
  }));

  // State to hold the selected color
  const [color, setColor] = useState("#0075c8");
  // State to manage visibility of the color picker
  const [showPicker, setShowPicker] = useState(false);

  // Function to handle color change
  const handleChangeComplete = (selectedColor) => {
    setColor(selectedColor.hex); // Update the state with the selected color
  };

  // Function to toggle color picker visibility
  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  // Function to handle input change
  const handleInputChange = (event) => {
    const inputColor = event.target.value;
    // Validate if the input is a valid hex color code
    if (/^#([0-9A-F]{3}){1,2}$/i.test(inputColor) || inputColor === "") {
      setColor(inputColor);
    }
  };

  const optionsRecordsPerPage = [
    { value: 20, label: "20 items per page" },
    { value: 50, label: "50 items per page" },
    { value: 100, label: "100 items per page" },
  ];
  const optionsCurrencyFormat = [
    { value: "Show Currency Text and Symbol", label: "Show Currency Text and Symbol" },
    { value: "Show Currency Text Only", label: "Show Currency Text Only" },
    { value: "Show Currency Symbol Only", label: "Show Currency Symbol Only" },
  ];
  const optionsUploadServer = [
    { value: "Current Server", label: "Current Server" },
    { value: "FTP", label: "FTP" },
    { value: "Wasabi", label: "Wasabi" },
    { value: "Digital Ocean", label: "Digital Ocean" },
  ];
  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">General Setting</h2>
          <p className="text-gray-500">Configure the fundamental
            information of the site.</p>
        </div>
      </header >
      <div className="mx-auto mt-2 px-8 py-4 ">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-md">
          <p className="text-black text-lg">Please be very careful about changing the File Upload Server. If you change the setting of File Upload Server, make sure to copy all product files to your new server. Otherwise, anything related to the product won't be shown on the site.</p>
        </div>
        <div className="mt-6 grid md:grid-cols-3 grid-cols-1 gap-8">
          <div>
            <label
              htmlFor="siteTitle"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Site Title *
            </label>
            <input
              type="text"
              id="siteTitle"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={siteTitle} // Bind input value to state
              onChange={handleSiteTitleInputChange} // Handle changes to input
              placeholder="Enter site title..."
            />
          </div>
          <div>
            <label
              htmlFor="currency"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Currency *
            </label>
            <input
              type="text"
              id="currency"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={currency} // Bind input value to state
              onChange={handleCurrencyChange} // Handle changes to input
              placeholder="Enter currency code (e.g., USD)"
            />
          </div>
          <div className="">
            <label
              htmlFor="currencySymbol"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Currency Symbol *
            </label>
            <input
              type="text"
              id="currencySymbol"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={currencySymbol} // Bind input value to state
              onChange={handleCurrencySymbolChange} // Handle changes to input
              placeholder="Enter currency symbol (e.g., $)"
            />
          </div>
          <div>
            <label
              htmlFor="timezone"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Timezone *
            </label>
            <Select
              id="timezone"
              options={timezoneOptions}
              defaultValue={{ value: "UTC", label: "UTC" }}
              className=" rounded text-gray-700"
              isSearchable
            />
          </div>
          <div>
            <label
              htmlFor="siteBaseColor"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Site Base Color *
            </label>
            <div className="flex items-center">
              {/* Clickable div to display and hide the color picker */}
              <div
                className="w-16 h-9 rounded rounded-r-none cursor-pointer"
                style={{ backgroundColor: color || "#ffffff" }} // Default background when input is empty
                onClick={togglePicker} // Toggle picker on click
              />
              {/* Input for hex color code */}
              <input
                type="text"
                id="siteBaseColor"
                className="shadow appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={color} // Show the selected color's hex code
                onChange={handleInputChange} // Handle manual input change
              />
            </div>
            {/* Conditionally render the color picker based on state */}
            {showPicker && (
              <SketchPicker
                className="mt-2"
                color={color} // Set the current color
                onChangeComplete={handleChangeComplete} // Handle color change
              />
            )}
          </div>
          <div>
            <label
              htmlFor="recordToDisplayPerPage"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Record to Display Per Page
            </label>
            <Select
              id="recordToDisplayPerPage"
              options={optionsRecordsPerPage}
              defaultValue={optionsRecordsPerPage[0]}
              className=" rounded  text-gray-700 "
              isSearchable
              placeholder="Select records per page..."
            />
          </div>
          <div>
            <label
              htmlFor="currencyShowingFormat"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Currency Showing Format *
            </label>
            <Select
              id="currencyShowingFormat"
              options={optionsCurrencyFormat}
              defaultValue={optionsCurrencyFormat[0]}
              className="rounded w-full text-gray-700"
              isSearchable
              placeholder="Select currency format..."
            />
          </div>
          <div>
            <label
              htmlFor="fileUploadServer"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              File Upload Server
            </label>
            <Select
              id="fileUploadServer"
              options={optionsUploadServer}
              defaultValue={optionsUploadServer[0]}
              className="rounded text-gray-700"
              isSearchable
              placeholder="Select file upload server..."
            />
          </div>
        </div>
        <div className="mt-6">
          <button className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline"
            type="submit"
            aria-label="Submit form" >
            Submit
          </button>
        </div>

      </div>
    </>
  )
}

export default GeneralSetting