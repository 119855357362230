import { useState } from 'react';

function ContactUs() {
  const [HeadingOne, setHeadingOne] = useState('Contact Us');
  const [subheadingOne, setSubheadingOne] = useState('Feel free to reach out to us anytime  were here to listen, answer your questions, and assist you in any way we can.');
  const [HeadingTwo, setHeadingTwo] = useState('Get in Touch');
  const [subheadingTwo, setSubheadingTwo] = useState('Reach out to us for swift assistance and friendly support');
  const [image, setImage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here, e.g., send data to a backend API
    console.log('Form submitted:', { HeadingOne, subheadingOne, HeadingTwo, subheadingTwo, image });
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <div className="bg-white px-8 py-4 rounded-md ">
      <h2 className="text-xl font-bold mb-4">Contact Us</h2>
      <form onSubmit={handleSubmit} className="border rounded-md">

        <div className="px-8 py-2">
          <label htmlFor="heading" className="block text-gray-700 font-bold mb-2">
            Heading One*
          </label>
          <input
            type="text"
            id="headingOne"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={HeadingOne}
            onChange={(e) => setHeadingOne(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="subheading" className="block text-gray-700 font-bold mb-2">
            Subheading One*
          </label>
          <input
            type="text"
            id="subheadingOne"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={subheadingOne}
            onChange={(e) => setSubheadingOne(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="heading" className="block text-gray-700 font-bold mb-2">
            Heading Two*
          </label>
          <input
            type="text"
            id="headingTwo"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={HeadingTwo}
            onChange={(e) => setHeadingTwo(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="subheading" className="block text-gray-700 font-bold mb-2">
            Subheading Two*
          </label>
          <input
            type="text"
            id="subheadingTwo"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={subheadingTwo}
            onChange={(e) => setSubheadingTwo(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="image" className="block text-gray-700 font-bold mb-2">
            Image
          </label>
          <input
            type="file"
            id="image"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            onChange={handleImageChange}
          />
          <p className="text-gray-600 text-sm mt-1">
            Supported Files: .png, .jpg, .jpeg. Image will be resized into 110x110px
          </p>
        </div>
        <div className='px-8 py-4'>
          <button
            type="submit"
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
