import React, { useState } from 'react';

function SystemConfiguration() {
  const [moduleStates, setModuleStates] = useState({
    userRegistration: true,
    forceSSL: false,
    agreePolicy: true,
    forceSecurePassword: false,
    kycVerification: true,
    emailVerification: false,
    emailNotification: true,
    mobileVerification: false,
    smsNotification: true,
    pushNotification: false,
    languageOption: true,
  });

  const handleModuleToggle = (moduleName) => {
    setModuleStates((prevState) => ({
      ...prevState,
      [moduleName]: !prevState[moduleName],
    }));
  };
  const handleSubmit = () => {
    // TODO: Handle form submission logic here
    console.log("submitted");
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">System Configuration</h2>
          <p className="text-gray-500">Control all of the basic modules of the system.</p>
        </div>
      </header >
      <div className='mx-auto mt-2 px-8 py-4 '>
        <div className="grid grid-cols-1">
          <div className="bg-white flex flex-wrap justify-between items-center  gap-8 rounded-t-lg border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">User Registration</h2>
              <p className="text-gray-700">If you disable this module, no one can register on this system.</p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.userRegistration ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('userRegistration')}
            >
              {moduleStates.userRegistration ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">Force SSL</h2>
              <p className="text-gray-700">By enabling Force SSL (Secure Sockets Layer) the system will force a visitor that he/she must have to visit in secure mode. Otherwise, the site will be loaded in secure mode</p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.forceSSL ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('forceSSL')}
            >
              {moduleStates.forceSSL ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">Agree Policy</h2>
              <p className="text-gray-700">If you enable this module, that means a user must have to agree with your system's policies during registration.</p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.agreePolicy ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('agreePolicy')}
            >
              {moduleStates.agreePolicy ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">Force Secure Password</h2>
              <p className="text-gray-700">By enabling this module, a user must set a secure password while signing up or changing the password.</p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.forceSecurePassword ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('forceSecurePassword')}
            >
              {moduleStates.forceSecurePassword ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">KYC Verification</h2>
              <p className="text-gray-700">If you enable KYC (Know Your Client) module, users must have to submit the required data. Otherwise, any money out transaction will be prevented by this system.</p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.kycVerification ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('kycVerification')}
            >
              {moduleStates.kycVerification ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">Email Verification</h2>
              <p className="text-gray-700">If you enable Email Verification, users have to verify their email to access the dashboard. A 6-digit verification code will be sent to their email to be verified. <br />
                Note: Make sure that the Email Notification module is enabled
              </p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.emailVerification ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('emailVerification')}
            >
              {moduleStates.emailVerification ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">Email Notification</h2>
              <p className="text-gray-700">If you enable this module, the system will send emails to users where needed. Otherwise, no email will be sent. So be sure before disabling this module that, the system doesn't need to send any emails.</p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.emailNotification ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('emailNotification')}
            >
              {moduleStates.emailNotification ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">Mobile Verification</h2>
              <p className="text-gray-700">If you enable Mobile Verification, users have to verify their mobile to access the dashboard. A 6-digit verification code will be sent to their mobile to be verified.
                Note: Make sure that the SMS Notification module is enabled</p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.mobileVerification ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('mobileVerification')}
            >
              {moduleStates.mobileVerification ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">SMS Notification</h2>
              <p className="text-gray-700">If you enable this module, the system will send SMS to users where needed. Otherwise, no SMS will be sent. So be sure before disabling this module that, the system doesn't need to send any SMS.</p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.smsNotification ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('smsNotification')}
            >
              {moduleStates.smsNotification ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">Push Notification</h2>
              <p className="text-gray-700">If you enable this module, the system will send push notifications to users. Otherwise, no push notification will be sent. Setting here
              </p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.pushNotification ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('pushNotification')}
            >
              {moduleStates.pushNotification ? 'Enable' : 'Disable'}
            </button>
          </div>
          <div className="bg-white flex flex-wrap justify-between items-center gap-8 border rounded-b-lg px-6 py-4">
            <div>
              <h2 className="text-lg font-bold mb-1">Language Option</h2>
              <p className="text-gray-700">If you enable this module, users can change the language according to their needs.</p>
            </div>
            <button
              className={`px-6 py-2 h-min  rounded-md ${moduleStates.languageOption ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              onClick={() => handleModuleToggle('languageOption')}
            >
              {moduleStates.languageOption ? 'Enable' : 'Disable'}
            </button>
          </div>
        </div>
        <div>
        <button
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  )
}

export default SystemConfiguration