import React, { useState } from 'react';

const data = {
  submittedBy: "Caps Tour",
  username: "@CapsTour",
  status: "Open",
  date: 'Posted on Sunday, 22nd September 2024 @ 08:57 pm',
  message: "Message",
  ticketNumber:"[Ticket#448692] "
};

const ReplyTicket = ({ ticketId, initialMessage }) => {
  const [replyText, setReplyText] = useState('');
  const [attachments, setAttachments] = useState([]);

  const handleReplyChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleFileChange = (e) => {
    setAttachments([...e.target.files]);
  };

  const handleSubmitReply = () => {
    // TODO: Implement your logic to send replyText and attachments to your backend. 
    console.log('Sending reply:', replyText, attachments);
  };

  const handleDeleteAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };
  const [fileInputs, setFileInputs] = useState([{}]); // Initialize with one file input

  // Handle adding a new file input
  const addFileInput = () => {
    if (fileInputs.length < 5) {
      setFileInputs([...fileInputs, {}]);
    }
  };

  // Handle file input change (this can be further developed to manage files if needed)
  const handleInputFileChange = (e, index) => {
    console.log(`Files for input ${index}:`, e.target.files);
  };

  return (
    <div className="bg-white shadow-md rounded-md p-6 my-4">
      <h1 className='text-lg font-semibold'>Reply Ticket</h1>
      <div className="flex justify-between items-center mb-4 mt-4">
        <div className='flex items-center gap-4'>
        <h2 className={`mt-1 text-sm border rounded-md w-min px-4 ${data.status === 'Open'
          ? 'bg-yellow-50 border-yellow-600 text-yellow-600'
          :  'bg-red-50 border-red-600 text-red-600'
          }`}>
          {data.status}
        </h2>
        <h2>{data.ticketNumber}</h2>
        </div>
        <button className='bg-red-50 border-red-500 text-red-400 hover:bg-red-500 hover:text-white font-semibold h-min p-2 text-sm border rounded-lg'>
          Close Ticket
        </button>
      </div>

      {/* Existing Message (If any) */}
      {initialMessage && (
        <div className="bg-gray-100 p-4 rounded-md mb-4">
          <p className="text-gray-700">{initialMessage}</p>
        </div>
      )}

      <textarea
        className="w-full p-3 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring focus:border-blue-300"
        rows="5"
        placeholder="Enter your reply here"
        value={replyText}
        onChange={handleReplyChange}
      />

      {/* Attachment Handling */}
      <div className="mt-4">
        <div className="flex flex-wrap justify-between items-center">
          <div>
            {fileInputs.length < 5 && (
              <button
                onClick={addFileInput}
                className="mt-2 bg-Select_text px-4 py-2 text-white rounded hover:bg-Select_text2 mb-4"
              >
                Add More Attachments
              </button>
            )}
            {fileInputs.map((input, index) => (
              <div key={index} className="mb-2">
                <input
                  type="file"
                  multiple
                  onChange={(e) => handleInputFileChange(e, index)}
                  className="border p-2"
                />
              </div>
            ))}

            {/* Display message when the limit is reached */}
            {fileInputs.length >= 5 && (
              <p className="text-red-500 mt-2">You can only upload up to 5 files.</p>
            )}
          </div>
          <button
            onClick={handleSubmitReply}
            className="mt-4 px-6 py-2 bg-Select_text text-white rounded-md hover:bg-Select_text2 focus:outline-none"
          >
            Reply
          </button>
        </div>
        <div className='flex flex-col justify-start px-8 w-3/6 py-4 gap-4 border rounded-md mt-4'>
          <div className='border-b pb-2'>
            <dt className="text-sm font-medium text-gray-500">Bubmitted By</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.submittedBy}</dd>
          </div>
          <div className='border-b pb-2'>
            <dt className="text-sm font-medium text-gray-500">Username</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.username}</dd>
          </div>
          <div className='border-b pb-2'>
            <dt className="text-sm font-medium text-gray-500">Date</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.date}</dd>
          </div>
          <div className='border-b pb-2'>
            <dt className="text-sm font-medium text-gray-500">Message</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.message}</dd>
          </div>
          <div>
            <button className='bg-red-50 border-red-500 text-red-400 hover:bg-red-500 hover:text-white font-semibold h-min p-2 text-sm border rounded-lg'><span className='flex items-center gap-2'>Delete </span></button>
          </div>
        </div>
      </div>


    </div>
  );
};

export default ReplyTicket;