import React from 'react';
import { Link } from 'react-router-dom';
import { IoSettingsOutline } from "react-icons/io5";

const ManageFrontend = () => {
  return (
    <div className="p-4 m-4 ">
      <h1 className="text-2xl font-bold mb-4">Content Management Options</h1>
      <div className="grid md:grid-cols-4 grid-cols-1 gap-6 px-8 mt-4">
        <Link
          to="/systemsettings/managefrontend/benefitSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Benefit Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/banSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Ban Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/bannerSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Banner Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/becomeAuthor"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Become Author
        </Link>
        <Link
          to="/systemsettings/managefrontend/collectiondefinition"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Collection Definition
        </Link>
        <Link
          to="/systemsettings/managefrontend/contactUs"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Contact Us
        </Link>
        <Link
          to="/systemsettings/managefrontend/ctaSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          CTA Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/faqSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Faq Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/featureSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Feature Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/featuredProductSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Featured Product Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/footerSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Footer Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/kycContent"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          KYC Content
        </Link>
        <Link
          to="/systemsettings/managefrontend/latestProductSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Latest Product Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/loginSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Login Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/marketPlaceSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Market Place Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/policyPages"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Policy Pages
        </Link>
        <Link
          to="/systemsettings/managefrontend/registerSection"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Register Section
        </Link>
        <Link
          to="/systemsettings/managefrontend/registrationDisable"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Registration Disable
        </Link>
        <Link
          to="/systemsettings/managefrontend/socialIcons"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Social Icons
        </Link>
        <Link
          to="/systemsettings/managefrontend/uploadTerm"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Upload Term
        </Link>
        <Link
          to="/systemsettings/managefrontend/weeklyBestSelling"
          className="bg-Select_text text-white flex justify-center px-4 py-4 rounded-md hover:bg-Select_text2"
        >
          Weekly Best Selling
        </Link>
      </div>
    </div>
  );
};

export default ManageFrontend;
