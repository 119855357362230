import { useState } from "react";

function UploadTerm() {
  const [details, setDetails] = useState("Any picture, sounds, video, code, blaze, or resources that are not my own work, have been praoperly authorized for use in the record see or primary download. Other than these things, this work is completely my own and I have full right to sell it on CodePlus.");


  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to a server
    console.log("Form submitted:", { details });
  };

  return (
    <div className="px-8 py-4">
      <h2 className="text-2xl font-bold mb-4">Upload Term</h2>
      <form onSubmit={handleSubmit} className="border rounded-md">
        <div className="px-8 py-2">
          <label htmlFor="details" className="block text-gray-700 font-bold mb-2">
            Details *
          </label>
          <input
            type="text"
            id="details"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </div>

        <div className="px-8 py-4">
          <button
            type="submit"
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default UploadTerm;
