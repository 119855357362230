import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa6';
import { IoMdAddCircleOutline } from "react-icons/io";
import { PiEyeSlashBold } from "react-icons/pi";
import { LuPencil } from "react-icons/lu";

function AuthorLevel() {
  const [data, setData] = useState([
    {
      selected: false,
      levelName: 'Level 1',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0afa527d4c1707126693.png',
      minEarning: '0.00',
      fee: '36.00',
      status: 'Enabled',
    },
    {
      selected: false,
      levelName: 'Level 2',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0afb4cd9901707126708.png',
      minEarning: '4000.00',
      fee: '32.00',
      status: 'Enabled',
    },
    {
      selected: false,
      levelName: 'Level 3',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0afc0de00e1707126720.png',
      minEarning: '12000.00',
      fee: '32.00',
      status: 'Enabled',
    },
    {
      selected: false,
      levelName: 'Level 4',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0afc87364c1707126728.png',
      minEarning: '15000.00',
      fee: '30.00',
      status: 'Enabled',
    },
    {
      selected: false,
      levelName: 'Level 5',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0afd07d12c1707126736.png',
      minEarning: '20000.00',
      fee: '28.00',
      status: 'Enabled',
    },
    {
      selected: false,
      levelName: 'Level 6',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0afd6dac571707126742.png',
      minEarning: '30000.00',
      fee: '25.00',
      status: 'Enabled',
    },
    {
      selected: false,
      levelName: 'Level 7',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0afdee45031707126750.png',
      minEarning: '40000.00',
      fee: '23.00',
      status: 'Enabled',
    },
    {
      selected: false,
      levelName: 'Level 8',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0afe6d6e4b1707126758.png',
      minEarning: '50000.00',
      fee: '20.00',
      status: 'Enabled',
    },
    {
      selected: false,
      levelName: 'Level 9',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0afefdbd7b1707126767.png',
      minEarning: '80000.00',
      fee: '15.00',
      status: 'Enabled',
    },
    {
      selected: false,
      levelName: 'Level 10',
      levelImg: 'https://script.viserlab.com/codeplus/assets/images/author_level/65c0affe0fc431707126782.png',
      minEarning: '100000.00',
      fee: '12.00',
      status: 'Enabled',
    },

  ]);
  const [showModal, setShowModal] = React.useState(false);
  const [name, setName] = useState('');
  const [currentLevel, setCurrentLevel] = useState(null);

  const [selectAll, setSelectAll] = useState(false);

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll }))); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected)); // If all checkboxes are selected, set selectAll to true
  };

  const handleEditClick = (level) => {
    setCurrentLevel(level);
    setShowModal(true);
  }

  const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility

  const handleAlertButtonClick = () => {
    setShowAlert(true); // Show the alert when the button is clicked
  };

  const handleConfirm = () => {
    //  Execute your unfeature action here
    console.log('Category unfeatured!');
    setShowAlert(false);
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  const [addname, setAddName] = useState("")
  const [minEarning, setMinEarning] = useState("")
  const [fee, setFee] = useState("")
  const [details, setDetails] = useState("")

  const [AddNewModal, setAddNewModal] = React.useState(false);

  const handleAddNewClick = () => {
    setAddNewModal(true);
  }
  const handleSubmit = () => {
    console.log("added new author")
  }
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  // Handle image upload and preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };
  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Authors Level</h2>
          <p className="text-gray-500"></p>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => handleAddNewClick(true)}
            className="bg-Select_text hover:bg-Select_text2 text-white font-semibold h-min p-3 text-sm rounded-lg">
            <span className='flex items-center gap-2'><IoMdAddCircleOutline /> Add New Author</span>
          </button>
        </div>
      </header >
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-full  border-collapse border">
            <thead className='border-b'>
              <th className='border-b'>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm e">Name</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Minimum Earnings
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">	Fee</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Status</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Action</th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-4 py-3 text-md text-start pl-4">
                    <div className='flex items-center justify-start gap-2'>
                      <img src={item.levelImg} alt="Product" className="w-10 h-10 inline-block rounded-md" />
                      <div className='flex flex-col'>
                        <span className=''>{item.levelName}</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    ${item.minEarning}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.fee}%
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.status}
                  </td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className='flex gap-2 flex-wrap'>
                      <button
                        onClick={() => handleEditClick(item)}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      ><LuPencil />
                      </button>
                      <button
                        onClick={handleAlertButtonClick}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#FF0404] hover:bg-[#FF0404] hover:text-white`}
                      ><PiEyeSlashBold />
                      </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">Showing 1 of 10 Results</p>
          <div className="flex gap-1">
            <button className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
              <FaAngleLeft />
            </button>
            <button className="bg-Select_text hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full">
              1
            </button>
            <button className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>

      {AddNewModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-1/3 p-8">
            <div className='flex justify-end'>
              <button onClick={() => setAddNewModal(false)} className="bg-transparent hover:bg-gray-200 text-gray-900 font-bold py-2 px-4 rounded-full">
                <IoCloseSharp />
              </button>
            </div>
            <h2 className='text-xl font-bold text-center'>Add New Author Level</h2>
            <div className='mt-4'>
              <div className='flex justify-center'>
                {/* Show image preview if available */}
                {imagePreview ? (
                  <img src={imagePreview} alt="Level" className="w-20 h-20 rounded-full border-2" />
                ) : (
                  <div className="w-20 h-20 rounded-full border-2 bg-gray-100 flex items-center justify-center">
                    <span className="text-gray-500">No Image</span>
                  </div>
                )}
              </div>

              <div className='mt-4'>
                <label htmlFor="imageUpload" className="block text-gray-700 font-bold mb-2">Upload Image:</label>
                <input
                  type="file"
                  id="imageUpload"
                  className="w-full mb-4 p-2 border rounded"
                  accept="image/*"
                  onChange={handleImageChange} // Handle image file input
                />
              </div>

              <div className='mt-4'>
                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name:</label>
                <input
                  type="text"
                  id="name"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter Name"
                  value={addname}
                  onChange={(e) => setAddName(e.target.value)}
                />
              </div>
              <div className='mt-4'>
                <label htmlFor="minEarning" className="block text-gray-700 font-bold mb-2">Minimum Earnings:</label>
                <div className='flex items-center'>
                  <input
                    type="number"
                    id="minEarning"
                    className="shadow appearance-none border rounded rounded-e-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Minimum Earnings"
                    value={minEarning}  // Assuming state for minEarning
                    onChange={(e) => setMinEarning(e.target.value)}
                  />
                  <h1 className='bg-gray-200 py-2 px-3 rounded-s-none rounded'>USD</h1>
                </div>
              </div>
              <div className='mt-4'>
                <label htmlFor="fee" className="block text-gray-700 font-bold mb-2">Fee:</label>
                <div className='flex items-center'>
                  <input
                    type="number"
                    id="fee"
                    className="shadow appearance-none border rounded rounded-e-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Fee"
                    value={fee}  // Assuming state for fee
                    onChange={(e) => setFee(e.target.value)}
                  />
                  <h1 className='bg-gray-200 py-2 px-3 rounded-s-none rounded'>%</h1>
                </div>
              </div>
              <div className='mt-4'>
                <label htmlFor="details" className="block text-gray-700 font-bold mb-2">Details:</label>
                <textarea
                  id="details"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter details"
                  value={details}  // Assuming state for details
                  onChange={(e) => setDetails(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-center mt-4">
                <button
                  className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-lg"
                  onClick={handleSubmit}  // Call a function to handle form submission
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-1/3 p-8">
            <div className='flex justify-end'>
              <button onClick={() => setShowModal(false)} className="bg-transparent hover:bg-gray-200 text-gray-900 font-bold py-2 px-4 rounded-full">
                <IoCloseSharp />
              </button>
            </div>
            <h2 className='text-xl font-bold text-center'>Update Author Level</h2>
            <div className='mt-4'>
              <div className='flex justify-center'>
                <img src={currentLevel?.levelImg} alt="Level Image" className="w-20 h-20 rounded-full border-2" />
              </div>
              <div className='mt-4'>
                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name:</label>
                <input
                  type="text"
                  id="name"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={currentLevel?.levelName}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className='mt-4'>
                <label htmlFor="minEarning" className="block text-gray-700 font-bold mb-2">Minimum Earnings:</label>
                <div className='flex items-center'>
                  <input
                    type="number"
                    id="minEarning"
                    className="shadow appearance-none border rounded rounded-e-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder={currentLevel?.minEarning}
                  // Add functionality to update minimum earning value
                  />
                  <h1 className='bg-gray-200 py-2 px-3 rounded-s-none rounded'>USD</h1>
                </div>
              </div>
              <div className='mt-4'>
                <label htmlFor="fee" className="block text-gray-700 font-bold mb-2">Fee:</label>
                <div className='flex items-center'>
                  <input
                    type="number"
                    id="fee"
                    className="shadow appearance-none border rounded rounded-e-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder={currentLevel?.fee}
                  // Add functionality to update fee value
                  />
                  <h1 className='bg-gray-200 py-2 px-3 rounded-s-none rounded'>%</h1>
                </div>
              </div>
              <div className='mt-4'>
                <label htmlFor="details" className="block text-gray-700 font-bold mb-2">Details:</label>
                <textarea
                  id="details"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter details"
                // Add functionality to update details value
                />
              </div>
              <div className="flex items-center justify-center mt-4">
                <button
                  className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-lg"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAlert && (
        <div className="fixed top-0 inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96 mt-10">
            <div className='flex justify-between items-center py-2'>
              <h2 className="text-lg font-bold">Confirmation Alert!</h2>
              <button className="font-bold" onClick={handleCancel}>X</button>
            </div>
            <p className='border-b border-t py-2'>Are you sure to disable this author level?</p>
            <div className="flex justify-end mt-4">
              <button className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-4 rounded-lg mr-2" onClick={handleCancel}>No</button>
              <button className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-lg" onClick={handleConfirm}>Yes</button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AuthorLevel