import { useState } from "react";

function KYCContent() {
  const [required, setRequired] = useState("Please submit the required KYC information to verify yourself. Otherwise, you couldn't make any withdrawal requests to the system.");
  const [pending, setPending] = useState("Your submitted KYC information is pending for admin approval. Please wait till that.");
  const [reject, setReject] = useState("Your KYC document has been rejected. Please resubmit the document for further review.");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to a server
    console.log("Form submitted:", { required, pending, reject });
  };

  return (
    <div className="px-8 py-4">
      <h2 className="text-2xl font-bold mb-4">KYC Content</h2>
      <form onSubmit={handleSubmit} className="border rounded-md">
        <div className="px-8 py-2">
          <label htmlFor="required" className="block text-gray-700 font-bold mb-2">
            Required *
          </label>
          <input
            type="text"
            id="required"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={required}
            onChange={(e) => setRequired(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="pending" className="block text-gray-700 font-bold mb-2">
            Pending *
          </label>
          <input
            type="text"
            id="pending"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={pending}
            onChange={(e) => setPending(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="reject" className="block text-gray-700 font-bold mb-2">
            Reject *
          </label>
          <input
            type="text"
            id="reject"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={reject}
            onChange={(e) => setReject(e.target.value)}
          />
        </div>
        <div className="px-8 py-4">
          <button
            type="submit"
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default KYCContent;
