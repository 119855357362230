import React, { useState, useEffect } from 'react';
import { RiLoader2Line } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

export default function PendingWithdrawal() {
  const [pendingWithdrawalCount, setPendingWithdrawalCount] = useState(5);

  useEffect(() => {
    // Fetch purchase count from API
    fetch('/api/purchase-count')
      .then(response => response.json())
      .then(data => setPendingWithdrawalCount(data.count));
  }, []);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/withdrawals/pendingwithdrawals');
  };

  return (
    <div onClick={handleButtonClick} className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-cyan-50">
      <div className="flex items-center gap-2">
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-cyan-300 border-l-[3px] text-cyan-500 text-xl">
          <RiLoader2Line />
        </div>
        <div className='flex flex-col'>
          <span className="text-xl font-bold">{pendingWithdrawalCount}</span>
          <span className="text-gray-500">Pending Withdrawals</span>
        </div>
      </div>
    </div>
  )
}
