import { useState } from 'react';
import { MdAdd, MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { LiaFilterSolid } from "react-icons/lia";
import { BsGrid1X2 } from "react-icons/bs";
import { MdOutlineShortText } from "react-icons/md";

function FAQSection() {
  const [heading, setHeading] = useState('Commonly Asked Question');
  const [subheading, setSubheading] = useState('Find answers to commonly asked questions right here for quick solutions');
  const [image, setImage] = useState(null);
  const [data, setData] = useState([
    { selected: false, sl: '1', question: "Are the digital products on your marketplace safe and virus-free?", answer: "Yes, we prioritize the safety and security of our users. All products undergo thorough checks, and sellers are vetted. We recommend using reputable antivirus software for an added layer of protection." },
    { selected: false, sl: '2', question: "Can I get a refund for a digital product?", answer: "Due to the nature of digital products, refunds are generally not provided. However, if you encounter any issues or have concerns, please contact our support team for assistance." },
    { selected: false, sl: '3', question: "How do I access and download my purchased digital products?", answer: "After completing your purchase, you'll receive an email with instructions on how to access and download your digital product. Additionally, you can find a download link in your account under the Purchases Item menu on your dashboard." },
    { selected: false, sl: '4', question: "What payment methods are accepted?", answer: "We accept various payment methods, including credit/debit cards, PayPal, and other secure payment gateways. Choose the option that suits you best during checkout." },
    { selected: false, sl: '5', question: "How do I purchase a digital product on your marketplace?", answer: "Simply browse our marketplace, select the desired product, and click on the Buy Now or Add to Cart button. Follow the checkout process to complete your purchase." },
  ]);

  const [selectAll, setSelectAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected));
  };

  const handleAddClick = () => {
    setQuestion('');
    setAnswer('');
    setIsModalOpen(true);
  };

  const handleEditClick = (index) => {
    const item = data[index];
    setCurrentIndex(index);
    setQuestion(item.question);
    setAnswer(item.answer);
    setIsEditModalOpen(true);
  };

  const handleAddSubmit = () => {
    const newEntry = { sl: (data.length + 1).toString(), question, answer, selected: false };
    setData([...data, newEntry]);
    setIsModalOpen(false);
  };

  const handleEditSubmit = () => {
    const updatedData = [...data];
    updatedData[currentIndex] = { ...updatedData[currentIndex], question, answer };
    setData(updatedData);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <div className="bg-white px-8 py-4 rounded-md ">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold mb-4">FAQ Section</h2>
          <button
            onClick={handleAddClick}
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-center flex items-center gap-2"
          >
            <MdAdd /> Add
          </button>
        </div>

        {/* Form for heading, subheading, and image */}
        <form onSubmit={e => e.preventDefault()} className="border rounded-md mt-2">
          <div className="px-8 py-2">
            <label htmlFor="heading" className="block text-gray-700 font-bold mb-2">Heading *</label>
            <input
              type="text"
              id="heading"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </div>
          <div className="px-8 py-2">
            <label htmlFor="subheading" className="block text-gray-700 font-bold mb-2">Subheading *</label>
            <input
              type="text"
              id="subheading"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={subheading}
              onChange={(e) => setSubheading(e.target.value)}
            />
          </div>
          <div className="px-8 py-2">
            <label htmlFor="image" className="block text-gray-700 font-bold mb-2">Image</label>
            <input
              type="file"
              id="image"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) => setImage(e.target.files[0])}
            />
            <p className="text-gray-600 text-sm mt-1">Supported Files: .png, .jpg, .jpeg. Image will be resized into 110x110px</p>
          </div>
        </form>
      </div>

      {/* Table for displaying FAQs */}
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className='border-b'>
              <tr>
                <th className='border-b'>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">SL</th>
                <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Question</th>
                <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-center">
                    <span className='text-tabledata_highlighted_text font-bold'>{item.sl}</span>
                  </td>
                  <td className="px-2 py-3 text-md text-center">{item.question}</td>
                  <td className="px-2 py-3 text-sm text-center">
                    <div className='flex gap-2 justify-center flex-wrap'>
                      <button
                        onClick={() => handleEditClick(index)}
                        className="px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white"
                      ><MdOutlineEdit />
                      </button>
                      <button
                        className="px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#FF0000] hover:bg-[#FF0000] hover:text-white"
                      ><MdDeleteOutline />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[30vw]">
            <div className='flex items-center justify-between py-2'>
              <h2 className="text-xl font-bold">Add New Faq Item</h2>
              <button
                className="font-bold"
                onClick={() => setIsModalOpen(false)}
              >
                X
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Question</label>
              <input
                type="text"
                className="w-full border px-4 py-2 rounded-md"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Answer</label>
              <textarea
                className="w-full border px-4 py-2 rounded-md"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                className="px-4 py-2 bg-Select_text hover:bg-Select_text2 text-white rounded-md"
                onClick={handleAddSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[30vw]">
            <div className='flex items-center justify-between py-2'>
              <h2 className="text-xl font-bold mb-4">Update Faq Item</h2>
              <button
                className="font-bold"
                onClick={() => setIsEditModalOpen(false)}
              >
                X
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Question</label>
              <input
                type="text"
                className="w-full border px-4 py-2 rounded-md"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Answer</label>
              <textarea
                className="w-full border px-4 py-6 rounded-md"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
            <div className="flex justify-end gap-2">

              <button
                className="px-4 py-2 bg-Select_text hover:bg-Select_text2 text-white rounded-md"
                onClick={handleEditSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FAQSection;
