import React, { useState } from 'react'


function Application() {
  const [data] = useState([
    {
      selected: false,
      codeplusVersion: '2.0',
      viserAdminVersion: '5.0.9',
      laravelVersion: '11.14.0',
      timezone: 'UTC'
    },
  ]);

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Application Information</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >
      <div className="mx-auto mt-2 p-4 w-[80vw] ">

        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-full  border-collapse border">
            <thead className='border-b'>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm e">Codeplus Version</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">ViserAdmin Version</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Laravel Version
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Timezone</th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.codeplusVersion}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.viserAdminVersion}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.laravelVersion}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.timezone}
                  </td>
                  </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </>
  )
}

export default Application