import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa6';
import { IoMdAddCircleOutline } from "react-icons/io";
import { CgScreen } from "react-icons/cg";
import { useNavigate } from 'react-router-dom';

function AnsweredTickets() {
  const [data, setData] = useState([
    {
      selected: false,
      subjectTicket: '[Ticket#58490484] ',
      subjectName: 'gpk2443',
      submittedBy: 'gpk2443',
      status: 'Answered',
      priority: 'Medium',
      lastReply: '2 days ago',
    },
  ]);
  const [showModal, setShowModal] = React.useState(false);
  const [name, setName] = useState('');

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 15; // Maximum number of rows per page
  const totalPages = Math.ceil(data.length / rowsPerPage); // Calculate total pages

  const [selectAll, setSelectAll] = useState(false);

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll }))); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected)); // If all checkboxes are selected, set selectAll to true
  };
  // Get current page data
  const currentPageData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const navigate = useNavigate();
  const handleTicketsDetails = () => {
    navigate(`/supportticket/ticketdetails`);
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Answered Tickets</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-full  border-collapse border">
            <thead className='border-b'>
              <th className='border-b'>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 flex justify-start text-black text-start font-bold uppercase text-sm e">
                Subject</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Submitted By
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Status</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">	Priority</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Last Reply</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Action</th>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <div className='flex gap-2'>
                      <span className=''>{item.subjectTicket}</span>
                      {item.subjectName}
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.submittedBy}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <span className='px-5 py-3 rounded-lg bg-green-50 border border-green-600 text-green-600'>{item.status}</span>
                  </td>
                  <td className={`px-2 py-3 text-md text-start pl-4`}>
                    <span className={` ${item.priority === "High"
                      ? "text-red-600 border-red-600 bg-red-50 px-5 py-3 rounded-lg border"
                      : item.priority === "Medium"
                        ? "text-yellow-600 border-yellow-600 bg-green-50 px-5 py-3 rounded-lg border"
                        : "text-cyan-600 border-cyan-600 bg-cyan-50 px-5 py-3 rounded-lg border"}`}>{item.priority}</span>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.lastReply}
                  </td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className='flex gap-2 flex-wrap'>
                      <button
                        onClick={() => handleTicketsDetails()}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      ><CgScreen />
                      </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">Showing {currentPageData.length} of {data.length} Results</p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${currentPage === index + 1 ? 'bg-orange-400 text-white' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full">
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div >

    </>
  )
}

export default AnsweredTickets