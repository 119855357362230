import { useState } from "react";

function CollectionDefinition() {
  const [heading, setHeading] = useState("What is a Collection?");
  const [details, setDetails] = useState("Collections are groups of items compiled by different users on a theme.  They can be set to Private for personal use, or Public so that they appear on this page and on a user's homepage.");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to a server
    console.log("Form submitted:", { heading, details });
  };

  return (
    <div className="px-8 py-4">
      <h2 className="text-2xl font-bold mb-4">Collection Definition</h2>
      <form onSubmit={handleSubmit} className="border rounded-md">
        <div className="px-8 py-2">
          <label htmlFor="heading" className="block text-gray-700 font-bold mb-2">
            Heading *
          </label>
          <input
            type="text"
            id="heading"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
          />
        </div>
        <div className="px-8 py-2">
          <label htmlFor="details" className="block text-gray-700 font-bold mb-2">
            Details *
          </label>
          <input
            type="text"
            id="details"
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </div>

        <div className="px-8 py-4">
          <button
            type="submit"
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default CollectionDefinition;
