import React, { useState } from 'react'
import { MdOutlineAlternateEmail } from "react-icons/md";
import { MdOutlineTextsms } from "react-icons/md";
function SendNotification() {
  const [selectedOption, setSelectedOption] = useState('email');
  const [formData, setFormData] = useState({
    sendTo: 'All Users',
    message: '',
    startForm: '',
    perBatch: '',
    coolingPeriod: '',
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here, e.g., send data to server
    console.log(formData);
  };
  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Notification to Verified Users</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >

      <div className="mx-auto mt-2 p-4 ">
        <div className="flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-md p-6 w-3/4">
            <div className='flex gap-4 border-b pb-4'>

              <button
                type="button"
                className={`px-4 py-2 border rounded-lg bg-Select_text hover:bg-Select_text2 text-white ${selectedOption === 'email' ? 'bg-blue-600' : ''
                  }`}
                onClick={() => handleOptionChange({ target: { value: 'email' } })}
              >
                <span className='flex gap-2 items-center'><MdOutlineAlternateEmail /> Send Via Email</span>
              </button>

              <button
                type="button"
                className={`px-4 py-2 border rounded-lg bg-Select_text2 hover:bg-Select_text text-white ${selectedOption === 'sms' ? 'bg-blue-600' : ''
                  }`}
                onClick={() => handleOptionChange({ target: { value: 'sms' } })}
              >
                <span className='flex gap-2 items-center'><MdOutlineTextsms /> Send Via SMS</span>
              </button>

            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4 mt-4">
                <label htmlFor="sendTo" className="block text-gray-700 text-sm font-bold mb-2">
                  Being Sent To *
                </label>
                <select
                  id="sendTo"
                  name="sendTo"
                  value={formData.sendTo}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="All Users">All Users</option>
                  <option value="Selected Users">Selected Users</option>
                  <option value="Kyc Unverified Users">Kyc Unverified Users</option>
                  <option value="Kyc Verified Users">Kyc Verified Users</option>
                  <option value="With Balance Users">With Balance Users</option>
                  <option value="Empty Balance Users">Empty Balance Users</option>
                  <option value="2FA Disable Users">2FA Disable Users</option>
                  <option value="2FA Enable Users">2FA Enable Users</option>
                  <option value="Deposited Users">Deposited Users</option>
                  <option value="Not Deposited Users">Not Deposited Users</option>
                  <option value="Pending Deposited Users">Pending Deposited Users</option>
                  <option value="Rejected Deposited Users">Rejected Deposited Users</option>
                  <option value="Top Deposited Users">Top Deposited Users</option>
                  <option value="Withdraw Users">Withdraw Users</option>
                  <option value="Pending Withdraw Users">Pending Withdraw Users</option>
                  <option value="Rejected Withdraw Users">Rejected Withdraw Users</option>
                  <option value="Pending Ticket Users">Pending Ticket Users</option>
                  <option value="Answer Ticket Users">Answer Ticket Users</option>
                  <option value="Closed Ticket Users">Closed Ticket Users</option>
                  <option value="Last Few Days Not Login Users">Last Few Days Not Login Users</option>
                  {/* Add more options for different recipient groups */}
                </select>
              </div>

              {selectedOption === 'email' && (
                <div className="mb-4">
                  <label htmlFor="subject" className="block text-gray-700 text-sm font-bold mb-2">
                    Subject *
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder='Subject / Title'
                    className="shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              )}

              <div className="mb-4">
                <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder='Enter Your Message .........'
                  className="shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ></textarea>
              </div>

              <div className="mb-4">
                <label htmlFor="startForm" className="block text-gray-700 text-sm font-bold mb-2">
                  Start Form *
                </label>
                <input
                  type="text"
                  id="startForm"
                  name="startForm"
                  value={formData.startForm}
                  onChange={handleChange}
                  placeholder='Start from user id. e.g.1'
                  className="shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="perBatch" className="block text-gray-700 text-sm font-bold mb-2">
                  Per Batch *
                </label>
                <div className='flex items-center border rounded-md'>
                  <input
                    type="text"
                    id="perBatch"
                    name="perBatch"
                    value={formData.perBatch}
                    onChange={handleChange}
                    placeholder='How many user'
                    className="shadow appearance-none rounded-r-none rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                  />
                  <span className="text-gray-700 bg-gray-200 rounded-md rounded-l-none  px-3 py-[6px]">User</span>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="coolingPeriod" className="block text-gray-700 text-sm font-bold mb-2">
                  Cooling Period *
                </label>
                <div className="flex items-center border rounded-md">
                  <input
                    type="text"
                    id="coolingPeriod"
                    name="coolingPeriod"
                    value={formData.coolingPeriod}
                    onChange={handleChange}
                    placeholder='Waiting time'
                    className="shadow appearance-none rounded-r-none rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <span className="text-gray-700 bg-gray-200 rounded-md rounded-l-none  px-3 py-[6px]">Seconds</span>
                </div>
              </div>
              <div className='border-t pt-4'>
                <button
                  type="submit"
                  className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>

    </>
  )
}

export default SendNotification