import React, { useState } from 'react';
import { MdAdd, MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { LiaFilterSolid } from "react-icons/lia";
import { BsGrid1X2 } from "react-icons/bs";
import { MdOutlineShortText } from "react-icons/md";

const BannerSection = () => {
  const [title, setTitle] = useState("Download 23,000+ Digital Resources");
  const [subtitle, setSubtitle] = useState("CodePlus offers a wide range of digital products, including PHP scripts, WordPress themes & plugins, website templates, graphics, and more.");
  const [counterTitle, setCounterTitle] = useState("More Than");
  const [counterSubtitle, setCounterSubtitle] = useState("2000+");

  const [showModal, setShowModal] = useState(false); // Modal state
  const [showEditModal, setShowEditModal] = useState(false); // Edit Modal state
  const [image, setImage] = useState(null);
  const [counterImage, setCounterImage] = useState(null);
  const [editImage, setEditImage] = useState(null); // Image for Edit Modal
  const [editingIndex, setEditingIndex] = useState(null); // Track which image is being edited

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted with:", counterTitle, counterSubtitle, title, subtitle, image, counterImage);
  };
  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handlecounterImageChange = (event) => {
    setCounterImage(event.target.files[0]);
  };

  const handleEditImageChange = (event) => {
    setEditImage(event.target.files[0]); // For Edit Modal
  };

  // Modal form data and handling
  const [modalcounterTitle, setModalcounterTitle] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");

  const handleModalSubmit = (event) => {
    event.preventDefault();
    // Update the main form with the modal input values
    setCounterTitle(modalcounterTitle);
    setTitle(modalTitle);
    setSubtitle(modalSubtitle);
    setShowModal(false); // Close the modal
  };

  // Function to open the modal
  const openModal = () => {
    setModalcounterTitle(counterTitle);
    setModalTitle(title);
    setModalSubtitle(subtitle);
    setShowModal(true);
  };

  const [data, setData] = useState([
    {
      selected: false,
      sl: '1',
      image: 'https://script.viserlab.com/codeplus/assets/images/frontend/banner/6540cb86351101698745222.png'
    },
    {
      selected: false,
      sl: '2',
      image: 'https://script.viserlab.com/codeplus/assets/images/frontend/banner/6540cb7d6aec01698745213.png'
    },
    {
      selected: false,
      sl: '3',
      image: 'https://script.viserlab.com/codeplus/assets/images/frontend/banner/6540cb6ca1f621698745196.png'
    },
    {
      selected: false,
      sl: '4',
      image: 'https://script.viserlab.com/codeplus/assets/images/frontend/banner/6540cb6317b8b1698745187.png'
    },
    {
      selected: false,
      sl: '5',
      image: 'https://script.viserlab.com/codeplus/assets/images/frontend/banner/6540cb5a7a24b1698745178.png'
    },
    {
      selected: false,
      sl: '6',
      image: 'https://script.viserlab.com/codeplus/assets/images/frontend/banner/6540cb5370e4c1698745171.png'
    },
    {
      selected: false,
      sl: '7',
      image: 'https://script.viserlab.com/codeplus/assets/images/frontend/banner/6540cb4958c281698745161.png'
    },
    {
      selected: false,
      sl: '8',
      image: 'https://script.viserlab.com/codeplus/assets/images/frontend/banner/6540cb3e696811698745150.png'
    },
  ]);

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected));
  };

  const handleEdit = (index) => {
    setEditingIndex(index); // Set the current index for editing
    setEditImage(data[index].image); // Set the current image for editing
    setShowEditModal(true); // Open the edit modal
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();

    // Update the image in the data array
    const updatedData = [...data];
    if (editImage) {
      updatedData[editingIndex].image = URL.createObjectURL(editImage); // Update the image
    }
    setData(updatedData);
    setShowEditModal(false); // Close the modal
  };

  return (
    <>
      <div className="px-8 py-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold mb-4">Banner Section</h2>
          <button onClick={openModal} className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-center flex items-center gap-2">
            <MdAdd />Add
          </button>
        </div>
        <form onSubmit={handleSubmit}>

          <div className="px-8 py-2">
            <label htmlFor="title" className="block text-gray-700 font-bold mb-2">
              Title *
            </label>
            <input
              type="text"
              id="title"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="px-8 py-2">
            <label htmlFor="subtitle" className="block text-gray-700 font-bold mb-2">
              Subtitle *
            </label>
            <input
              type="text"
              id="subtitle"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />
          </div>
          <div className="px-8 py-2">
            <label htmlFor="counterTitle" className="block text-gray-700 font-bold mb-2">
              Counter Title *
            </label>
            <input
              type="text"
              id="counterTitle"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={counterTitle}
              onChange={(e) => setCounterTitle(e.target.value)}
            />
          </div>
          <div className="px-8 py-2">
            <label htmlFor="counterSubtitle" className="block text-gray-700 font-bold mb-2">
              counter Subtitle *
            </label>
            <input
              type="text"
              id="counterSubtitle"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={counterSubtitle}
              onChange={(e) => setCounterSubtitle(e.target.value)}
            />
          </div>
          <div className="flex flex-wrap md:flex-nowrap gap-16 px-8 py-2">
            <div className="mb-4 md:w-1/2 w-full">
              <label htmlFor="image" className="block text-gray-700 font-bold mb-2">
                Image
              </label>
              <input
                type="file"
                id="image"
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageChange}
              />
              <p className="text-gray-600 text-sm mt-1">
                Supported Files: .png, .jpg, .jpeg. Image will be resized into
                635x470px
              </p>
            </div>
            <div className="mb-4 md:w-1/2 w-full">
              <label htmlFor="icon-image" className="block text-gray-700 font-bold mb-2">
                Counter Image
              </label>
              <input
                type="file"
                id="icon-image"
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handlecounterImageChange}
              />
              <p className="text-gray-600 text-sm mt-1">
                Supported Files: .png, .jpg, .jpeg. Image will be resized into
                30x30px
              </p>
            </div>
          </div>
          <div className="px-8 py-2">
            <button
              type="submit"
              className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-full  border-collapse border">
            <thead className='border-b'>
              <th className='border-b'>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">SL</th>

              <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Image</th>

              <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Action</th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-center ">
                    <span className='text-tabledata_highlighted_text font-bold'>{item.sl}</span>
                  </td>

                  <td className="px-2 py-3 text-md text-center"><img src={item.image} alt="Product" className="w-8 h-8 inline-block rounded-full border-2" /></td>

                  <td className="px-2 py-3 text-sm text-center">
                    <div className='flex gap-2 justify-center flex-wrap'>

                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                        onClick={() => handleEdit(index)}
                      ><MdOutlineEdit />
                      </button>
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#FF0000] hover:bg-[#FF0000] hover:text-white`}
                      ><MdDeleteOutline />
                      </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal Implementation */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
            <div className='flex items-center justify-between py-2'>
              <h2 className="text-xl font-bold">Add Benefit Details</h2>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="font-bold"
              >
                X
              </button>
            </div>
            <form onSubmit={handleModalSubmit}>
              <div className="mb-4  ">
                <label htmlFor="image" className="block text-gray-700 font-bold mb-2">
                  Image
                </label>
                <input
                  type="file"
                  id="image"
                  className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleImageChange}
                />
                <p className="text-gray-600 text-sm mt-1">
                  Supported Files: .png, .jpg, .jpeg. Image will be resized into
                  635x470px
                </p>
              </div>
              <div className="flex justify-between">
                <button type="submit" className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded">
                  Submit
                </button>

              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Modal Implementation */}
      {showEditModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
            <div className='flex items-center justify-between py-2'>
              <h2 className="text-xl font-bold">Edit Image</h2>
              <button onClick={() => setShowEditModal(false)}>X</button>
            </div>
            <form onSubmit={handleEditSubmit}>
              <div className="mb-4">
                <label htmlFor="currentImage" className="block text-sm font-medium text-gray-700">Current Image</label>
                <img src={editImage} alt="Current Image" className="w-32 h-32 mt-2" />
              </div>
              <div className="mb-4">
                <label htmlFor="newImage" className="block text-sm font-medium text-gray-700">Upload New Image</label>
                <input
                  type="file"
                  id="newImage"
                  className="block w-full border rounded-md p-2 mt-1"
                  onChange={handleEditImageChange}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-Select_text text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerSection;
