import React, { useState } from 'react'


function Server() {
  const [data] = useState([
    {
      selected: false,
      phpVersion: '8.3.10',
      serverSoftware: 'LiteSpeed',
      serverIPAddress: '216.158.71.218',
      serverProtocol: 'HTTP/1.1',
      httpHost: 'script.viserlab.com',
      serverPort: '443'
    },
  ]);

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Server Information</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >
      <div className="mx-auto mt-2 p-4 w-[80vw] ">

        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-full  border-collapse border">
            <thead className='border-b'>

              <th className="px-4 py-4 flex justify-start text-black text-start font-bold uppercase text-sm e">PHP Version</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Server Software</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Server IP Address
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Server Protocol</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">HTTP Host</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Server Port</th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.phpVersion}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.serverSoftware}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.serverIPAddress}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.serverProtocol}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.httpHost}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.serverPort}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>

    </>
  )
}

export default Server