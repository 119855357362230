import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
import { LiaFilterSolid } from "react-icons/lia";
import { BsGrid1X2 } from "react-icons/bs";
import { MdOutlineShortText } from "react-icons/md";
import { PiEyeSlash } from "react-icons/pi";
import { MdOutlineEdit } from "react-icons/md";

function ManualGateways() {
  const [data, setData] = useState([
    {
      selected: false,
      gateway: 'Bank Transfer',
      status: "Enabled",
    },

  ]);
  const [showModal, setShowModal] = React.useState(false);
  const [name, setName] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 15; // Maximum number of rows per page
  const totalPages = Math.ceil(data.length / rowsPerPage); // Calculate total pages

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll }))); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected)); // If all checkboxes are selected, set selectAll to true
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Manual Gateways</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className="table-auto w-full  border-collapse border">
            <thead className='border-b'>
              <th className='border-b'>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Gateway</th>

              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Status</th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">Action</th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4 ">
                    <span className=''>{item.gateway}</span>
                  </td>

                  <td className="px-2 py-3 text-md text-start pl-4"><span className='px-5 py-3 rounded-lg bg-green-100 border border-green-600 text-green-600'>{item.status}</span></td>

                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className='flex gap-2 justify-start flex-wrap'>
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      ><MdOutlineEdit />
                      </button>
                      <button
                        className={` px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#FF0000] hover:bg-[#FF0000] hover:text-white`}
                      ><PiEyeSlash />
                      </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>

    </>
  )
}

export default ManualGateways