import React, { useState } from 'react';
import { FaCircleInfo } from "react-icons/fa6";
function Updates() {


  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Clear System Cache</h2>
          <p className="text-gray-500"></p>
        </div>
      </header >
      <div className="mx-auto mt-2 p-4 w-3/5">
        <div className="bg-gray-100 flex items-center justify-center">
          <div className="bg-white border border-red-600 rounded-lg shadow-md p-8">
            <div className="text-center mb-6">
              <h2 className="text-7xl font-bold text-orange-500">2.0</h2>
              <p className="text-3xl font-bold text-orange-500">Your Version</p>
            </div>
            <div className="flex gap-2">
              <span className='text-indigo-500 text-lg'><FaCircleInfo /></span>
              <p className="ml-2 text-gray-600">
                <strong> You are currently using the latest version of the system. </strong>We are committed to continuous
                improvement and are actively developing the next version. Stay tuned for exciting new
                features and enhancements to be released soon!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Updates;