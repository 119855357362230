import { useState } from 'react';
import { MdAdd, MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { LiaFilterSolid } from "react-icons/lia";
import { BsGrid1X2 } from "react-icons/bs";
import { MdOutlineShortText } from "react-icons/md";

function FeatureSection() {
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');

  const [data, setData] = useState([
    {
      selected: false,
      sl: '1',
      title: "",
      featureIcon: ""
    },

  ]);

  const [selectAll, setSelectAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map(row => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every(row => row.selected));
  };

  const handleAddClick = () => {
    setTitle('');
    setDescription('');
    setIsModalOpen(true);
  };

  const handleEditClick = (index) => {
    const item = data[index];
    setCurrentIndex(index);
    setTitle(item.question);
    setDescription(item.answer);
    setIsEditModalOpen(true);
  };

  const handleAddSubmit = () => {
    const newEntry = { sl: (data.length + 1).toString(), title, description, selected: false };
    setData([...data, newEntry]);
    setIsModalOpen(false);
  };

  const handleEditSubmit = () => {
    const updatedData = [...data];
    updatedData[currentIndex] = { ...updatedData[currentIndex], title, description };
    setData(updatedData);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <div className="bg-white px-8 py-4 rounded-md ">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold mb-4">Feature Section</h2>
          <button
            onClick={handleAddClick}
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-center flex items-center gap-2"
          >
            <MdAdd /> Add
          </button>
        </div>

        {/* Form for heading, subheading, and image */}
        <form onSubmit={e => e.preventDefault()} className="border rounded-md mt-2">
          <div className="px-8 py-2">
            <label htmlFor="heading" className="block text-gray-700 font-bold mb-2">Heading *</label>
            <input
              type="text"
              id="heading"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </div>
          <div className="px-8 py-2">
            <label htmlFor="subheading" className="block text-gray-700 font-bold mb-2">Subheading *</label>
            <input
              type="text"
              id="subheading"
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={subheading}
              onChange={(e) => setSubheading(e.target.value)}
            />
          </div>
        </form>
      </div>

      {/* Table for displaying FAQs */}
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className='text-2xl' />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className='border-b'>
              <tr>
                <th className='border-b'>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">SL</th>
                <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Title</th>
                <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Feature icon</th>
                <th className="px-4 py-4 text-black text-center font-bold uppercase text-sm">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-center">
                    <span className='text-tabledata_highlighted_text font-bold'>{item.sl}</span>
                  </td>
                  <td className="px-2 py-3 text-md text-center">{item.title}</td>
                  <td className="px-2 py-3 text-md text-center">{item.featureIcon}</td>
                  <td className="px-2 py-3 text-sm text-center">
                    <div className='flex gap-2 justify-center flex-wrap'>
                      <button
                        className="px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white"
                      ><MdOutlineEdit />
                      </button>
                      <button
                        className="px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#FF0000] hover:bg-[#FF0000] hover:text-white"
                      ><MdDeleteOutline />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[30vw]">
            <div className='flex items-center justify-between py-2'>
              <h2 className="text-xl font-bold">Add New Feature Item</h2>
              <button
                className="font-bold"
                onClick={() => setIsModalOpen(false)}
              >
                X
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Title</label>
              <input
                type="text"
                className="w-full border px-4 py-2 rounded-md"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Description</label>
              <textarea
                className="w-full border px-4 py-2 rounded-md"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                className="px-4 py-2 bg-Select_text hover:bg-Select_text2 text-white rounded-md"
                onClick={handleAddSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}


    </>
  );
}

export default FeatureSection;
