import React from 'react'
import { IoIosClock } from "react-icons/io";
import { SlHome } from "react-icons/sl";
import cartoon from '../images/cartoon.svg'
import TotalUsers from '../Dashboardcards/Users/TotalUsers';
import ActiveUsers from '../Dashboardcards/Users/ActiveUsers';
import EmailUnverifiedPage from '../Dashboardcards/Users/EmailUnverifiedPage';
import MobileUnverifiedPage from '../Dashboardcards/Users/MobileUnverifiedPage';
import TotalDeposits from '../Dashboardcards/Deposits/TotalDeposits';
import PendingDeposits from '../Dashboardcards/Deposits/PendingDeposits';
import RejectedDeposits from '../Dashboardcards/Deposits/RejectedDeposits';
import DepositedCharge from '../Dashboardcards/Deposits/DepositedCharge';
import TotalWithdrawal from '../Dashboardcards/Withdrawals/TotalWithdrawal';
import PendingWithdrawal from '../Dashboardcards/Withdrawals/PendingWithdrawals';
import RejectedWithdrawal from '../Dashboardcards/Withdrawals/RejectedWithdrawals';
import WithdrawalCharge from '../Dashboardcards/Withdrawals/WithdrawalsCharges';
import SalesAmount from '../Dashboardcards/Sales/SalesAmount';
import SellerCharge from '../Dashboardcards/Sales/SellerCharge';
import BuyerCharge from '../Dashboardcards/Sales/BuyerCharge';
import TotalRefund from '../Dashboardcards/Sales/TotalRefund';
import TotalPayment from '../Dashboardcards/Payment/TotalPayment';
import PendingPayment from '../Dashboardcards/Payment/PendingPayment';
import RejectedPayment from '../Dashboardcards/Payment/RejectedPayment';
import PaidCharge from '../Dashboardcards/Payment/PaidCharge';
import ChartsPage from '../Dashboardcards/ChartsPage';

function Dashboard() {
  const ChartData = [
    {
      "label": "Jan",
      "value": 45
    },
    {
      "label": "Feb",
      "value": 20
    },
    {
      "label": "Mar",
      "value": 32
    },
    {
      "label": "Apr",
      "value": 12
    },
  ]
  return (
    <>
      <header className='flex justify-between px-6 py-2 '>
        <h1 className='font-semibold text-xl'>Dashboard</h1>
        <div className='flex items-center gap-1'>
          <SlHome />
          <span>/</span>
          <span className='text-blue-500'>Dashboard</span>
        </div>
      </header>
      <div className='bg-white p-6'>
        <div className='flex flex-wrap justify-between gap-6 px-8'>
          <div className='border-2 px-10 py-4 w-[600px] rounded-2xl bg-gradient-to-r from-[#439FF2] to-[#7366FF] md:block hidden'>
            <div className='w-56 text-white ms-4'>
              <h1 className='font-bold text-lg'>Welcome to AspCart</h1>
              <p className='text-xs mt-2 w-80'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus omnis esse id ut, in consequuntur corporis? Sequi accusamus animi aspernatur vero suscipit.</p>
              <div className='ms-[450px] mt-[-50px] md:block hidden'>
                <span className='text-5xl'><IoIosClock /></span>
              </div>
            </div>
            <div className='md:block hidden'>
              <button className='border-white hover:bg-white hover:text-black font-semibold border rounded-md text-white px-4 py-2 mt-6 ms-4 text-sm'>Whats New</button>
              <img className='w-52 h-32 ms-72 mb-[-28px] mt-[-80px]' src=
                {cartoon} alt="" />
            </div>
          </div>
          {/* users */}
          <div className='grid bg-gray-50 md:grid-cols-2 grid-cols-1 gap-6'>
            <TotalUsers />
            <ActiveUsers />
            <EmailUnverifiedPage />
            <MobileUnverifiedPage />
          </div>
        </div>
        {/* Deposit and Withdrawals */}
        <div className='grid md:grid-cols-2  grid-cols-1 gap-4 mt-8'>
          <div className='bg-gray-50 rounded-lg ps-6 p-4'>
            <h1 className='font-semibold text-lg mb-2'>Deposits</h1>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-6 '>
              <TotalDeposits />
              <PendingDeposits />
              <RejectedDeposits />
              <DepositedCharge />
            </div>
          </div>
          <div className='bg-gray-50 rounded-lg ps-6 p-4'>
            <h1 className='font-semibold text-lg mb-2'>Withdrawals</h1>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-6 '>
              <TotalWithdrawal />
              <PendingWithdrawal />
              <RejectedWithdrawal />
              <WithdrawalCharge />
            </div>
          </div>
          {/* sales and Payment */}
        </div>
        <div className='grid grid-cols-1 gap-4 mt-8'>
          <div className='bg-gray-50 rounded-lg ps-6 p-4'>
            <h1 className='font-semibold text-lg mb-2'>Sales</h1>
            <div className='grid md:grid-cols-4 grid-cols-1 gap-6 '>
              <SalesAmount />
              <SellerCharge />
              <BuyerCharge />
              <TotalRefund />
            </div>
          </div>
          <div className='bg-gray-50 rounded-lg ps-6 p-4'>
            <h1 className='font-semibold text-lg mb-2'>Payment</h1>
            <div className='grid md:grid-cols-4 grid-cols-1 gap-6 '>
              <TotalPayment />
              <PendingPayment />
              <RejectedPayment />
              <PaidCharge />
            </div>
          </div>

        </div>
        <div>

        </div>
      </div>
      <div>
        <ChartsPage />
      </div>
    </>
  )
}

export default Dashboard